<template>
  <section class="depositions">
    <h2 class="text--title text-align--center text-color--primary">Depoimentos</h2>

    <div class="depositions__wrapper">
      <DepositionItem
        v-for="deposition in depositions"
        v-bind="deposition"
        :key="deposition.image"
      />
    </div>

    <div class="depositions__controls">
      <button @click.prevent="prev">
        <ion-icon class="arrow" name="chevron-back-outline" />
      </button>
      <div class="depositions__dots">
        <button v-for="(item, index) in depositions" :key="item.image" @click.prevent="goTo(index)">
          <ion-icon class="dot" :name="getDotIcon(index)" />
        </button>
      </div>
      <button @click.prevent="next">
        <ion-icon class="arrow" name="chevron-forward-outline" />
      </button>
    </div>
  </section>
</template>

<script>
import Siema from 'siema';
import DepositionItem from './DepositionItem';

export default {
  props: [
      'depositions'
  ],
  components: { DepositionItem },
  data() {
    return {
      activeIndex: 0,
      siema: null,
    }
  },
  mounted() {
    this.siema = new Siema({
      selector: '.depositions__wrapper',
      loop: true,
      onChange: this.changeIndex,
      duration: 500,
      perPage: 1
    });
  },
  methods: {
    changeIndex(index = null) {
      const newIndex = !index ? this.siema.currentSlide : index;

      this.activeIndex = newIndex;
    },
    next() {
      this.siema.next();
    },
    prev() {
      this.siema.prev();
    },
    goTo(index) {
      this.siema.goTo(index);
      this.changeIndex(index);
    },
    getDotIcon(index) {
      return `ellipse${index === this.activeIndex ? '' : '-outline'}`;
    }
  }
}
</script>

<style lang="stylus" scoped>
.depositions
  margin 36px 0px 40px

  @media screen and (min-width 768px)
    margin 50px 0

  @media screen and (min-width 1048px)
    margin 60px 0

  &__wrapper
    margin 24px 0

  &__controls
    display flex
    justify-content center
    align-items center
    gap 16px

    button
      width 16px
      height 16px
      color #00519D
      padding 0
      background-color transparent
      border 0
      cursor pointer

    .arrow
      font-size 16px

    .dot
      font-size 10px

  &__dots
    height 16px

    button + button
      margin-left 4px
</style>
