<template>
  <div class="footer-locaweb">
    <a href="https://menv.io/lwsa" target="_blank" rel="noreferrer noopener">
      <svg class="footer-locaweb__logo">
        <use xlink:href="/images/svg-symbols.svg#lwsa"></use>
      </svg>
    </a>
    <p class="footer-locaweb__text">@{{ currentYear }} Melhor Envio, uma empresa LWSA</p>
  </div>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style lang="stylus" scoped>
  .footer-locaweb
    background #fff
    border-top 6px solid #011331
    padding 20px
    display flex
    flex-direction column
    justify-content center
    align-items center

    &__text
      font-size 12px
      color rgba(104, 104, 104, 0.65)

    &__logo
      width 120px
      height 18px
      margin-bottom 14px
</style>
