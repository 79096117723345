<template>
  <div class="calculator-table-container">
    <h2 class="text--big text-color--primary">Calculadora de fretes simplificada</h2>
    <spinner v-if="sentRequest"/>
    <div
      v-else
      class="card calculator-table">
      <table>
        <tr align="center">
          <th class="table-header">
            <p class="table-header-text">Transportadora</p>
          </th>
          <th class="table-header">
            <p class="table-header-text">Modalidade</p>
          </th>
          <th class="table-header">
            <p class="table-header-text "><span>Prazo</span> Estimado
              <ion-icon
                class="table-header-icon"
                name="information-circle"
                v-tooltip.top="{content: $options.static.staticDelivery, classes: ['v-tooltip--centered']}"
              />
            </p>
          </th>
          <th
            v-if="!logged"
            class="table-header">
            <p class="table-header-text"><span>Valor</span> no balcão</p>
          </th>
          <th class="table-header">
            <p class="table-header-text"><span>Valor</span> estimado do frete</p>
          </th>
          <th></th>
          <th
            v-if="logged"
            class="table-header"/>
        </tr>

        <tr v-for="(value, i) in shippingValues" :key="`${i}-shippingvalue`">
          <template v-if="value.error">
            <td>
              <img :src="value.company.picture" :alt="value.company.name">
            </td>
            <td>
              <template>{{ value.name }}</template>
            </td>
            <td colspan="3">{{ value.error }}</td>
            <td>
              <button
                class="btn-border -disabled -only-icon"
                title="Ir para a página de cadastro"
                disabled
              >
                <i aria-hidden="true">
                  <svg class="icon">
                    <use xlink:href="/images/svg-symbols.svg#arrow-right"></use>
                  </svg>
                </i>
              </button>
            </td>
          </template>

          <template v-else>
            <td>
              <img :src="value.company.picture" :alt="value.company.name">
            </td>
            <td>
              <template>{{ value.name }}</template>
            </td>
            <td>
              <div v-if="value.delivery_range">
                <span>{{ value.delivery_range ? formatDeliveryRange(value.delivery_range) : '' }}</span>
              </div>
            </td>
            <template>
              <td :class="{'equals-discount': value.discount == 0 }">
                <p class="line-through">R$ {{ formatPrices(calcDeskPrice(value)) }}</p>
              </td>
              <td>
                <p class="final-price">
                  R$ {{ formatPrices(value.price) }}*
                </p>
              </td>
              <td>
                <a
                  href="/cadastre-se"
                  class="btn-border -full-blue -only-icon"
                  title="Ir para a página de cadastro"
                >
                  <i aria-hidden="true">
                    <svg class="icon">
                      <use xlink:href="/images/svg-symbols.svg#arrow-right"></use>
                    </svg>
                  </i>
                </a>
              </td>
            </template>
          </template>
        </tr>
      </table>
    </div>

    <div
      v-if="!sentRequest"
      class="calculator-cards-mobile">
      <div
        v-for="(value, i) in shippingValues"
        :key="`${i}-cardshippingvalue`"
        class="card"
      >
        <template v-if="value.error">
          <div class="img-operation-name">
            <div>
              <img :src="value.company.picture" :alt="value.company.name">
            </div>
          </div>
          <div class="shipping-text-info">
            <div class="text-wraper">
              <p class="title-info initial-text">Modalidade</p>
              <p class="final-text">
                <template>{{ value.name }}</template>
              </p>
            </div>
          </div>
          <div class="shipping-text-info">
            <div class="text-wraper">
              <p class="operation-error">{{ value.error }}</p>
            </div>

            <button
              class="btn-border -disabled"
              title="Ir para a página de cadastro"
              disabled
            >
              Selecionar
            </button>
          </div>
        </template>

        <template v-else>
          <div class="img-operation-name">
            <div>
              <img :src="value.company.picture" :alt="value.company.name">
            </div>
          </div>
          <div class="shipping-text-info">
            <div class="text-wraper">
              <p class="title-info initial-text">Modalidade</p>
              <p class="final-text">
                <template>{{ value.name }}</template>
              </p>
            </div>
            <div class="text-wraper">
              <p class="title-info initial-text">Prazo Estimado</p>
              <p class="final-text">{{ value.delivery_range ? formatDeliveryRange(value.delivery_range) : '' }}
                <ion-icon
                  class="table-header-icon"
                  name="information-circle"
                  v-tooltip.top="{content: $options.static.staticDelivery, classes: ['v-tooltip--centered']}"
                />
              </p>
            </div>
            <div
              v-if="!logged && value.discount > 0"
              :class="['text-wraper', {'equals-discount': value.discount == 0 }]">
              <p class="title-info initial-text">Valor no balcão</p>
              <p class="line-through final-text">R$ {{ formatPrices(calcDeskPrice(value)) }}</p>
            </div>
            <div class="shipping-price-info">
              <div class="text-wraper">
                <p class="title-info initial-text">Valor do frete</p>
                <p class="final-price final-text">R$ {{formatPrices(value.price)}} </p>
              </div>

              <a
                href="/cadastre-se"
                class="btn-border -full -full-blue"
                title="Ir para a página de cadastro"
              >
                Selecionar
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="calculator-footer">
      <p>*Os valores são estimativas e podem variar devido a taxas, cubagem, quantidade de envios e outros fatores. Cadastre-se e conheça cotações exclusivas para seu negócio.</p>
    </div>
    <div
      v-if="!sentRequest"
      class="calculator-buttons-row">
      <div class="button-box low-padding-btn">
        <button
          class="btn-shadow"
          @click="recalculate()"
        >
          <i class="ico ico-small-arrow-calculator">
            <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch -->
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Calculadora" transform="translate(-275.000000, -572.000000)" fill="#0550A0" fill-rule="nonzero">
                        <g id="Componente/Botão/Primário" transform="translate(252.000000, 554.000000)">
                            <g id="arrow-back" transform="translate(23.000000, 18.000000)">
                                <path d="M14.6438095,7.344 L5.33333333,7.344 L6.93333333,8.904 C7.1862727,9.14932746 7.33011276,9.49444549 7.33011276,9.856 C7.33011276,10.2175545 7.1862727,10.5626725 6.93333333,10.808 C6.39735073,11.3343331 5.56455403,11.3343331 5.02857143,10.808 L1.15809524,6.968 C0.905155871,6.72267254 0.76131581,6.37755451 0.76131581,6.016 C0.76131581,5.65444549 0.905155871,5.30932746 1.15809524,5.064 L5.02857143,1.192 C5.56455403,0.665666939 6.39735073,0.665666939 6.93333333,1.192 C7.1862727,1.43732746 7.33011276,1.78244549 7.33011276,2.144 C7.33011276,2.50555451 7.1862727,2.85067254 6.93333333,3.096 L5.33333333,4.656 L14.6438095,4.656 C14.9934306,4.64523666 15.3327904,4.78082456 15.587158,5.03290573 C15.8415257,5.28498691 15.9900412,5.63288896 16,6 C15.9900412,6.36711104 15.8415257,6.71501309 15.587158,6.96709427 C15.3327904,7.21917544 14.9934306,7.35476334 14.6438095,7.344 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
          </i>
          recalcular
        </button>
      </div>
      <template v-if="!logged">
        <div class="button-box sign-up-btn-desktop">
          <a href="/cadastre-se" class="btn-border -full-blue">
            Cadastre-se grátis
          </a>
        </div>
        <div class="button-box sign-up-btn-mobile">
          <a href="/cadastre-se" class="btn-border -full-blue">
            Cadastre-se grátis
          </a>
        </div>
        <div class="button-box low-padding-btn">
          <button
            class="btn-shadow"
            @click="modalOpened = true">
            como funciona?
          </button>
        </div>
      </template>
    </div>

    <how-it-is-possible v-if="modalOpened"/>
    <insured-amount v-if="insuredModalOpened" />
  </div>
</template>

<script>
import HowItIsPossible from '../Modals/HowItIsPossible'
import InsuredAmount from '../Modals/InsuredAmount'
import { EventBuss } from '../../events/eventBuss.js'
import Spinner from '../Spinner'

export default {
  static: {
    staticDelivery: `
      <p>
        A partir da data de postagem.
        <a href="https://centraldeajuda.melhorenvio.com.br/hc/pt-br/articles/31220422959124-Conhe%C3%A7a-o-Ponto-Parceiro-Pegaki" target="_blank" rel="noopener noreferer">Entenda</a>
      </p>
    `,
  },

  components: {
    'how-it-is-possible': HowItIsPossible,
    'insured-amount': InsuredAmount,
    'spinner': Spinner
  },
  data () {
    return {
      modalOpened: false,
      insuredModalOpened: false
    }
  },
  computed: {
    shippingValues () {
      return this.$store.state.shippingValues
    },
    logged () {
      return this.$store.state.logged
    },
    sentRequest () {
      return this.$store.state.sentRequest
    }
  },
  mounted () {
    this.modalClosed()
  },
  methods: {
    recalculate () {
      this.$store.commit('recalculate');
      const calculatorTop = document.querySelector('#calculator');
      window.scrollTo(0, calculatorTop.offsetTop - 98);
      this.$router.back();
    },
    formatDeliveryRange (date) {
      const { min } = date;

      if (!min) {
        return '-';
      }

      return min === 1 ? '1 dia útil' : `${min} dias úteis`;
    },
    calcDeskPrice (value) {
      const finalValue = parseFloat(value.price) + parseFloat(value.discount)

      return finalValue.toFixed(2)
    },
    formatPrices (value) {
      let formatedValue = parseFloat(value)

      formatedValue = formatedValue.toFixed(2)

      formatedValue = String(formatedValue)

      return formatedValue.replace('.', ',')
    },
    modalClosed () {
      EventBuss.$on('calculator-modal-closed', () => {
        this.modalOpened = false
      })
      EventBuss.$on('insured-modal-closed', () => {
        this.insuredModalOpened = false
      })
    },
    showChooseButton (value) {
      if (value.company.name === 'Correios') {
        return true
      } else if (this.$store.state.form.insuranceValue) {
        return true
      }
      return false
    }
  }
}
</script>
