<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AppHome'
}
</script>

<style lang="stylus">
  @import "assets/styles"
</style>
