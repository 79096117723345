export const melhorPonto = {
  title: "Melhor Ponto: poste suas encomendas em um só lugar",
  description: `
    Evite filas e deixe pacotes de diferentes transportadoras em uma das
    nossas unidades de postagem espalhadas pelo Brasil.
  `,
  imageName: "mel_MP.png",
  imageAlt: "Mel com camiseta do melhor ponto segurando o celular",
  icons: [
    {
      iconPath: require("../assets/img/svg/mp/box.svg"),
      text: "Acesse modalidades de frete exclusivas"
    },
    {
      iconPath: require("../assets/img/svg/mp/bank.svg"),
      text: "Economize tempo, dinheiro e combustível"
    },
    {
      iconPath: require("../assets/img/svg/mp/coins.svg"),
      text: "Pague apenas pelas etiquetas que gerar"
    }
  ]
}

export const melhorRastreio = {
  title: "Melhor Rastreio: rastreie encomendas gratuitamente",
  description: `
    A plataforma de rastreamento mais completa do mercado para quem
    quer atualizações descomplicadas de suas encomendas.
  `,
  imageName: "mel_MR.png",
  imageAlt: "Mel com camiseta do melhor ponto olhando através de uma lupa",
  icons: [
    {
      iconPath: require("../assets/img/svg/mr/clock.svg"),
      text: "Acompanhe as movimentações dos seus envios em tempo real"
    },
    {
      iconPath: require("../assets/img/svg/mr/hands.svg"),
      text: "Tenha mais pontos de contato e esteja mais próximo dos seus clientes"
    },
    {
      iconPath: require("../assets/img/svg/mr/screen.svg"),
      text: "Cadastre-se e aproveite a ferramenta gratuita"
    }
  ]
}
