export const whatWeDoForYou = [
  {
    title: "Sem mensalidades",
    description: "Seus únicos custos são com as etiquetas que usar",
    icon: require("../assets/img/svg/whatWeDoForYou/no-fee.svg")
  },
  {
    title: "Diversas integrações",
    description: "Conecte o Melhor Envio às plataformas de e-commerce que sua empresa já usa",
    icon: require("../assets/img/svg/whatWeDoForYou/integrations.svg")
  },
  {
    title: "Coleta gratuita",
    description: "Possibilidade de garantir coleta gratuita e otimizar sua logística*",
    icon: require("../assets/img/svg/whatWeDoForYou/colect.svg")
  },
  {
    title: "Geração de fretes automatizada",
    description: "Reduza esforços e foque no mais importante: suas vendas",
    icon: require("../assets/img/svg/whatWeDoForYou/lamp.svg")
  }
]
