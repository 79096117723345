<template>
  <div class="partners-badges">
    <div class="partners-badges__content">
      <h3 class="partners-badges__title text--title text-color--white">
        Conectamos você às principais transportadoras do Brasil
      </h3>
      <p class="partners-badges__description secondary-text--big text-color--white">
        Calcule fretes nas principais transportadoras ao mesmo tempo e
        escolha o mais vantajoso para enviar suas encomendas.
      </p>
      <div class="partners-badges__badges">
        <div
          class="partners-badges__badges--badge"
          v-for="(logo, index) in logos"
          :key="index"
          :class="{
            'partners-badges__badges--badge-link': ('url' in logo)
          }"
          @click="redirectToUrl(logo)"
        >
          <img :src="logo.imagePath" :alt="logo.alt">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { companiesLogos } from '../config/partnersLogos'

export default {
  data() {
    return {
      logos: companiesLogos
    }
  },

  methods: {
    redirectToUrl({url}) {
      if (!url) {
        return;
      }

      window.scrollTo(0, 0);
      this.$router.push({ path: url })
    }
  },
}
</script>

<style lang="stylus" scoped>
.partners-badges
  display flex
  justify-content center
  align-items center
  width 100%
  background-color #00519D
  background-size 450px
  background-image url("../assets/img/pattern-partners.svg")
  background-repeat repeat

  @media screen and (min-width 1048px)
    padding 60px 0

  &__content
    max-width 1024px
    margin 40px 24px
    text-align center

    @media screen and (min-width 768px)
      margin 50px 24px

    @media screen and (min-width 1048px)
      margin 0

  &__badges

    display flex
    justify-content center
    align-items stretch
    flex-wrap: wrap
    gap 16px
    margin 24px auto 0

    @media screen and (min-width 768px)
      max-width 600px

    @media screen and (min-width 1048px)
      max-width 710px

    &--badge
      display flex
      justify-content center
      align-items center
      padding 16px
      border-radius 4px
      background-color white

      img
        width 72px
        display block

        @media screen and (min-width 768px)
          width 90px

      &-link
        cursor pointer
</style>
