export const questionsAndAnswers = [
  {
    title: "O que é o Melhor Envio?",
    text: `
      <p>Somos uma plataforma gratuita de gestão e intermediação de fretes. Nossa missão é simplificar a logística do e-commerce.</p>
      <p>
        Fazemos isso <strong>oferecendo fretes baratos e acesso a diversas transportadoras
        e Correios de forma prática, integrada e livre de contratos</strong>.
      </p>
    `
  },
  {
    title: "Como calcular frete?",
    text: `
      <p>
        Para <strong>calcular o frete nos Correios e transportadoras privadas</strong>, basta acessar a Calculadora de Fretes do
        Melhor Envio e preencher as informações de CEPs de origem e destino, de peso e de dimensões da encomenda.
      </p>
      <p>
        Em poucos segundos, você encontra várias opções de entrega com diferentes transportadoras
        e pode escolher o melhor frete para o seu negócio.
      </p>
      <p>
        Tanto os Correios quanto as demais transportadoras fazem o cálculo de frete com as mesmas informações que você vai
        inserir na Calculadora de Fretes do Melhor Envio.
      </p>
      <p>
        A vantagem é que aqui você tem acesso a preços exclusivos e pode calcular o frete em diversas opções de envio
        de uma só vez. Isso significa <strong>economia de tempo e dinheiro para o seu e-commerce</strong>.
      </p>
    `
  },
  {
    title: "Todos os fretes têm desconto?",
    text: `
      <p>
        Sim! Como intermediamos um grande volume de envios, conseguimos negociar fretes mais baratos com as
        transportadoras e Correios, oferecendo condições especiais para nossos clientes.
      </p>
      <p>
        Faça a cotação de fretes na Calculadora do Melhor Envio e comprove a economia!
      </p>
    `
  },
  {
    title: "Precisa ter CNPJ para se cadastrar no Melhor Envio?",
    text: `
      <p>
        Não precisa! O cadastro no Melhor Envio é feito usando seu CPF. Você pode adicionar seu CNPJ, mas é opcional.
        <a href="https://menv.io/cadastrar-minha-empresa" target="_blank" rel="noopener noreferrer">Saiba mais!</a>
      </p>
    `
  },
  {
    title: "Quais são o peso e as dimensões máximas que meu envio pode ter?",
    text: `
      <p>
        Os limites de peso e dimensões das cargas dependem da modalidade de frete e da transportadora escolhida para o envio.
        <a href="https://menv.io/pesos-e-dimensoes#-dimenses-da-carga" target="_blank" rel="noopener noreferrer">
        Confira os limites de cada modalidade aqui</a>.
      </p>
    `
  },
  {
    title: "Como economizar no frete?",
    text: `
      <p>
        Com o Melhor Envio, os fretes são <strong>até 80% mais baratos que os preços do balcão das transportadoras</strong>,
        mas é possível economizar ainda mais com os <strong>fretes centralizados</strong> – modalidade exclusiva do
        <a href="https://www.melhorponto.com.br/" target="_blank" rel="noopener noreferrer">
        Melhor Ponto</a>, nossas unidades físicas de postagem espalhadas por todo o Brasil.
      </p>
      <p>
        Sempre que a calculadora mostrar uma modalidade indicada como <strong>centralizado</strong>, você está vendo um
        <strong>frete mais barato, com desconto adicional já aplicado</strong>.
      </p>
    `
  },
  {
    title: "Como calcular frete pelo celular?",
    text: `
      <p>
        Você também pode <strong>calcular frete nos Correios e transportadoras parceiras</strong> do Melhor Envio
        <strong>no seu celular</strong>.
      </p>
      <p>
        É só acessar a plataforma normalmente e seguir os passos para calcular frete, escolher a melhor opção de envio e
        realizar o pagamento das etiquetas de frete.
      </p>
      <p>
        Mas lembre-se que, <strong>para imprimir as etiquetas de frete, é preciso que o seu celular esteja
        conectado a uma impressora</strong>.
      </p>
    `
  },
]
