<template>
  <section>
    <div class="hero" :style="{backgroundColor: getBackgroundColor}">
      <div class="hero__container" :class="{'hero__container--reverse': hasHomePromotionalBanner}">
        <div class="hero__content">
          <h1 class="text--hero" :style="{color: getColors.primary}">
            {{ getTexts.title }}
          </h1>
          <h2 class="secondary-text--hero" :style="{color: getColors.secondary}" v-html="getTexts.subtitle"></h2>

          <div class="button-box">
            <h2>
              <a
                class="button button--primary"
                href="/cadastre-se"
                title="Cadastre-se grátis"
              >
                Cadastre-se grátis
              </a>
            </h2>

            <p v-if="getTexts.span" :style="{color: getColors.secondary}" class="hero__span">
              {{ getTexts.span }}
            </p>
          </div>
        </div>
        <PictureImage
          folderName="home"
          :fileName="getIllustration.fileName"
          :altText="getIllustration.alt"
          :classNames="getIllustration.classes"
          :useIntersect="false"
        />
      </div>

      <div class="calculator-container">
        <Calculator />
      </div>
    </div>
  </section>
</template>

<script>
import Calculator from '../components/Calculator/Calculator'
import PictureImage from '../components/Common/Picture'

export default {
  components: {
    Calculator,
    PictureImage
  },

  data() {
    return {
      hasHomePromotionalBanner: Boolean(MEConfig.get('banner.promotional'))
    }
  },

  computed: {
    getBackgroundColor() {
      return this.hasHomePromotionalBanner ? '#f2f6f9' : '#f2f6f9';
    },

    getIllustration() {
      const defaultIllustration = {
        fileName: 'mel_hero.png',
        alt: 'Ilustração da Mel',
        classes: 'hero__illustration hero__illustration--mel'
      }

      const promotional = {
        fileName: 'home_10anos.svg',
        alt: 'Faça sua entrega acontecer, a partir de R$6,85',
        classes: 'hero__illustration hero__illustration--promotional'
      }

      return this.hasHomePromotionalBanner ? promotional : defaultIllustration;
    },

    getTexts() {
      const defaultTexts = {
        title: 'Comece a calcular fretes com o Melhor Envio e economize',
        subtitle: 'Fretes a partir de R$7,16 em uma plataforma gratuita, sem mensalidades e sem burocracia.'
      }

      const promotional = {
        title: 'Frete barato e segurança para você vender mais',
        subtitle: 'Ganhe 10% OFF em 10 envios<br>com o cupom: MELHOR10EM10*',
        span: '*Oferta válida para novos clientes'
      }

      return this.hasHomePromotionalBanner ? promotional : defaultTexts;
    },

    getColors() {
      const defaultColors = {
        primary: '#00519d',
        secondary: '#3b3f51'
      }

      const promotional = {
        primary: '#1D3757',
        secondary: '#1D3757'
      }

      return this.hasHomePromotionalBanner ? promotional : defaultColors;
    }
  }
}
</script>

<style lang="stylus" scoped>
.hero
.calculator-container
  position relative

.hero
  width 100%
  padding-bottom 40px

  @media screen and (min-width 768px)
    padding-bottom 60px

  .button-box
    display flex
    flex-direction column
    align-items center

    @media screen and (min-width 768px)
      align-items start

  &__container
    z-index 2
    position relative
    width 85vw
    max-width 980px
    padding-top 100px
    margin 0 auto
    display flex
    flex-direction column
    gap 32px
    align-items center
    justify-content space-between

    @media screen and (min-width 768px)
      padding-top 120px
      flex-direction row

    &--reverse
      flex-direction column-reverse
      gap 24px
      padding-bottom 24px

      @media screen and (min-width 768px)
        flex-direction row
        padding-bottom 0

  &__content
    text-align center
    display flex
    flex-direction column
    align-items center
    gap 32px

    @media screen and (min-width 768px)
      padding 32px 0
      align-items start
      text-align left
      width 60%
      float left

  >>> &__illustration
    max-width 300px

    &--mel
      margin-bottom -25px

    &--promotional
      width 300px

    @media screen and (min-width 768px)
      max-width 450px

      &--mel
        margin-bottom 0px

      &--promotional
        margin-bottom 0
        width 420px
        height 420px

  &__span
    margin-top 8px
</style>
