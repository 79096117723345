<template>
  <transition name="modal-fade">
    <div class="calculator-intern-modal" @click="handleClick">
      <div class="card">
        <div
          class="intern-modal-close"
          @click="handleClicker">
          <i class="ico ico-close-btn-small">
            <img :src="closeButton" alt="">
          </i>
        </div>
        <h5 class="heading-5">Como funciona?</h5>
        <div class="steps-container">
          <div class="step">
            <img
              class="step-icon"
              :src="negociamos" />
            <p>Tenha acesso às principais transportadoras do Brasil</p>
          </div>
          <div class="step">
            <img
              class="step-icon"
              :src="repassamos" />
            <p>Aproveite fretes com desconto de até 80%</p>
          </div>
          <div class="step">
            <img
              class="step-icon"
              :src="ferramentas" />
            <p>Simplifique sua logística com um <a href="/cadastre-se">cadastro gratuito</a></p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBuss } from '../../events/eventBuss.js'
import CloseButtonSmall from '../../assets/img/svg/close-btn-small.svg'
import Ferramentas from '../../assets/img/svg/ferramentas.svg'
import Negociamos from '../../assets/img/svg/negociamos.svg'
import Repassamos from '../../assets/img/svg/repassamos.svg'

export default {
  data() {
    return {
      closeButton: CloseButtonSmall,
      ferramentas: Ferramentas,
      negociamos: Negociamos,
      repassamos: Repassamos
    }
  },
  methods: {
    handleClick() {
      if(event.target.className === 'calculator-intern-modal')
        EventBuss.$emit('calculator-modal-closed')
    },
    handleClicker() {
      EventBuss.$emit('calculator-modal-closed')
    }
  }
}
</script>
