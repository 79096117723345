export default function (value) {
  if (!value) {
    return true
  }

  value = String(value)

  let matches = value.match(/(\,|\.)(\d{1,3})$/)

  if (matches && matches.length >= 3) {
    value = value.substr(0, value.length - 3).replace(/\D/g, '') + '.' + matches[2]
  }

  let real = parseInt(value)

  return !isNaN(real) && isFinite(real) && real >= 1 && real <= 300
}
