<template>
  <footer class="information-footer">
    <div class="information-footer__columns">
      <div class="information-footer__column information-footer__column--awards">
        <img
          width="200px"
          src="../assets/img/footer/solucao_do_ano_2022.svg"
          alt="Prêmio solução do ano 2022"
        >
        <img
          width="150px"
          src="../assets/img/footer/melhor_logistica_2020.svg"
          alt="Prêmio de melhor logística no e-commerce em 2020"
        >
      </div>
      <div class="information-footer__column">
        <ul class="information-footer__links-list">
          <li class="information-footer__list-item information-footer__list-item-header">
            melhor envio
          </li>
          <li class="information-footer__list-item">
            <a href="/" title="Página Inicial">
              página inicial
            </a>
          </li>
          <li class="information-footer__list-item">
            <a :href="supportPublicDashUrl" title="Suporte" target="_blank" rel="noopener noreferrer">
              suporte
            </a>
          </li>
          <li class="information-footer__list-item">
            <a href="https://menv.io/parceiros" title="Programa de parceiros" target="_blank" rel="noopener noreferrer">
              parceiros
            </a>
          </li>
          <li class="information-footer__list-item">
            <a href="https://menv.io/trabalhe-conosco" title="Trabalhe Conosco" target="_blank" rel="noopener noreferrer">
              trabalhe conosco
            </a>
          </li>
        </ul>
      </div>
      <div class="information-footer__column">
        <ul class="information-footer__links-list">
          <li class="information-footer__list-item information-footer__list-item-header">
            outros produtos
          </li>
          <li class="information-footer__list-item">
            <a href="https://www.melhorrastreio.com.br" title="Melhor Rastreio" target="_blank" rel="noopener noreferrer">
              melhor rastreio
            </a>
          </li>
          <li class="information-footer__list-item">
            <a href="https://menv.io/faq" title="Central de Ajuda" target="_blank" rel="noopener noreferrer">
              central de ajuda
            </a>
          </li>
          <li class="information-footer__list-item">
            <a href="https://melhorenvio.com.br/blog" title="Blog" target="_blank" rel="noopener noreferrer">
              blog melhor envio
            </a>
          </li>
          <li class="information-footer__list-item">
            <a href="https://docs.menv.io" title="Desenvolvedor" target="_blank" rel="noopener noreferrer">
              desenvolvedor
            </a>
          </li>
          <li v-if="isDesktop" class="information-footer__list-item information-footer__list-item--download-app">
            <a
              href="https://menv.io/app-melhor-envio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/ilustrations/google-play-logo.svg"
                alt="Aplicativo Melhor Envio - Google Store"
              >
            </a>
          </li>
        </ul>
      </div>
      <div class="information-footer__column">
        <ul class="information-footer__links-list">
          <li
            class="
              information-footer__list-item
              information-footer__list-item-header
            "
          >
            Melhor Envio {{currentYear}}
          </li>
          <li class="
              information-footer__list-item
              information-footer__list-item-header
            "
          >
            Todos os direitos reservados
          </li>
          <li class="information-footer__list-item">
            <a
              href="/contratos-e-politicas"
              title="Contratos e políticas"
              target="_blank"
              rel="noopener noreferrer"
            >
              contratos e políticas
            </a>
          </li>
          <li class="information-footer__list-item">
            <div class="information-footer__social-networks">
              <a href="https://www.facebook.com/MelhorEnvio/" title="Facebook" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-facebook" class="icon"></ion-icon>
              </a>
              <a href="https://www.youtube.com/melhorenviooficial" title="Youtube" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-youtube" class="icon"></ion-icon>
              </a>
              <a href="https://www.instagram.com/melhorenvio/" title="Instagram" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-instagram" class="icon"></ion-icon>
              </a>
              <a href="https://www.tiktok.com/@melhorenvio" title="Tiktok" target="_blank" rel="noopener noreferrer">
                <img src="/images/svg/tiktok_logo.svg" alt="Logo Tiktok" class="icon">
              </a>
              <a href="https://www.linkedin.com/company/melhorenvio/" title="Linkedin" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-linkedin" class="icon"></ion-icon>
              </a>
            </div>
          </li>
          <li v-if="!isDesktop" class="information-footer__list-item information-footer__list-item--download-app">
            <a
              href="https://menv.io/app-melhor-envio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/ilustrations/google-play-logo.svg"
                alt="Aplicativo Melhor Envio - Google Store"
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
    <FooterLocaweb />
  </footer>
</template>

<script>
// COMPONENTS
import FooterLocaweb from './FooterLocaweb.vue';

// MIXINS
import isMobile from 'commons/js/mixins/isMobile';

export default {
  components: { FooterLocaweb },

  mixins: [isMobile],

  data() {
    return {
      supportPublicDashUrl: ''
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.supportPublicDashUrl = MEConfig.get('dash.url') + '/suporte/';
  }
}
</script>

<style lang="stylus" scoped>
.information-footer
  color white
  font-size 12px
  font-weight 400
  background-color #26303C
  padding 0

  &__columns
    max-width 980px
    padding 40px 24px
    margin 0 auto
    text-align center

    display grid
    grid-template-rows repeat(4, min-content)
    grid-template-columns 1fr
    gap 24px

    @media screen and (min-width 1048px)
      grid-template-columns repeat(4, max-content)
      grid-template-rows 1fr
      justify-content space-between
      align-items start
      gap 40px

  &__column
    &--awards
      display flex
      flex-direction column
      align-items center
      justify-content center
      gap 24px

      @media screen and (min-width 1048px)
        flex-direction row
        gap 40px

  &__links-list
    display flex
    flex-direction column
    justify-content center
    gap 8px

  &__list-item
    &-header
      font-weight bold

    a
      color inherit
      text-decoration inherit
      &:hover
        text-decoration underline

    &--download-app

      a
        display inline-block
        padding 12px

  &__social-networks
    margin-top 16px
    display flex
    justify-content center
    align-items center
    gap 8px

    a
      .icon
        height 16px
        width 16px
        fill white
        color white

</style>
