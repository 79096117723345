<template>
  <transition name="modal-fade">
    <div class="calculator-intern-modal">
      <div class="card">
        <div
          class="intern-modal-close"
          @click="handleClick">
          <i class="ico ico-close-btn-small">
            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch -->
                <desc>Created with Sketch.</desc>
                <defs>
                    <polygon id="path-1" points="2.75280899e-05 0 13.9655388 0 13.9655388 13.9654719 2.75280899e-05 13.9654719"></polygon>
                </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.148716518">
                    <g id="Como-é-possível?" transform="translate(-1013.000000, -180.000000)">
                        <g id="Close" transform="translate(1013.000000, 180.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlink:href="#path-1"></use>
                            </mask>
                            <g id="Clip-2"></g>
                            <path d="M8.09515393,6.98271236 L13.7351876,1.34267865 C14.0423225,1.03554382 14.0423225,0.537521348 13.7351876,0.230386517 C13.4281315,-0.0767483146 12.930109,-0.0767483146 12.6228169,0.230307865 L6.98278315,5.87034157 L1.34267079,0.230307865 C1.0354573,-0.0768269663 0.537592135,-0.0767483146 0.230378652,0.230386517 C-0.0767561798,0.537521348 -0.0767561798,1.03554382 0.230378652,1.34267865 L5.87049101,6.98271236 L0.230378652,12.6227461 C-0.0767561798,12.9299596 -0.0767561798,13.427982 0.230378652,13.7351169 C0.383985393,13.8887236 0.585255056,13.9654876 0.786524719,13.9654876 C0.987794382,13.9654876 1.1891427,13.8886449 1.34267079,13.7351169 L6.98278315,8.09508315 L12.6228169,13.7351169 C12.7765022,13.8887236 12.9776933,13.9654876 13.1790416,13.9654876 C13.3803112,13.9654876 13.5815809,13.8886449 13.7351876,13.7351169 C14.0423225,13.427982 14.0423225,12.9299596 13.7351876,12.6227461 L8.09515393,6.98271236 Z" id="Fill-1" fill="#000000" fill-rule="evenodd" mask="url(#mask-2)"></path>
                        </g>
                    </g>
                </g>
            </svg>
          </i>
        </div>
        <div class="card-content-intern">
          <h5 class="heading-5">Informe o valor segurado</h5>
          <p class="intern-modal-text">O valor do seguro deve ser o mesmo do produto, conforme consta na nota fisca. Envios com declaração de conteúdo (aqueles de remetentes não contribuintes ou de objetos não comercializados) podem ser segurados até R$ 1.000,00 e não há cobertura por avarias.</p>
          <div class="form-insurance">
            <form
              class="me-form"
              @submit.prevent="onSubmit">
              <div class="formBox calc-input-last">
                <fieldset
                  :class="{ active: insuranceValue, error: $v.insuranceValue.$error, focus: focus.insuranceValue }"
                  class="formLine"
                >
                  <div class="inputBox">
                    <label :for="'insuranceValue'">Valor da Carga</label>
                    <input
                      v-mask="'money'"
                      id="insuranceValue"
                      ref="insuranceValue"
                      v-model="insuranceValue"
                      type="text"
                      name="insuranceValue"
                      @keypress="stripTheGarbage($event)"
                      @focus="iptFocus('insuranceValue', $event)"
                      @blur="iptBlur('insuranceValue', $event)"
                    >
                    <span
                      v-if="focus.insuranceValue || insuranceValue "
                      class="span-after"
                      @click="setFocus('insuranceValue')"
                    >
                      R$
                    </span>
                  </div>
                </fieldset>
              </div>
              <div class="button-box" >
                <button
                  class="btn-border -full-blue"
                  type="submit"
                >CALCULAR</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBuss } from '../../events/eventBuss.js'
import { required } from 'vuelidate/lib/validators'
import AwesomeMask from 'awesome-mask'

export default {
  directives: {
    'mask': AwesomeMask
  },
  data () {
    return {
      insuranceValue: '',
      focus: {
        insuranceValue: false
      },
      reverse: false
    }
  },
  methods: {
    formatMoney () {
      if (this.insuranceValue !== '') {
        if (this.insuranceValue.includes('R$')) {
          this.insuranceValue = this.insuranceValue.split(' ')[1]
        }
        let num = this.insuranceValue
        num = Number(num)

        let countDecimals = (value) => {
          if (Math.floor(value) === value) return 0
          return value.toString().split('.')[1].length || 0
        }

        const decimal = countDecimals(num)

        if (decimal < 2) {
          num = num.toFixed(2)
        }

        if (decimal > 2) {
          num = num.toFixed(2)
        }

        if (parseInt(num) < 1) {
          num = '.' + String(num).split('.')[1]
        }

        this.insuranceValue = `R$ ${num}`
      }
    },
    stripTheGarbage (e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault()
      }
    },
    iptFocus (ipt) {
      this.focus[ipt] = true
    },
    iptBlur (ipt) {
      this.focus[ipt] = false
    },
    handleClick () {
      EventBuss.$emit('insured-modal-closed')
    },
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      EventBuss.$emit('insured-modal-closed')
      const calculatorTop = document.querySelector('#calculator')
      window.scrollTo(0, calculatorTop.offsetTop - 98)
      this.$store.commit('UPDATE_INSURANCE_VALUE', this.insuranceValue)
      this.$store.dispatch('calculateValues', this.$store.state.form)
    }
  },
  validations: {
    insuranceValue: {
      required
    }
  }
}
</script>
