export default function (value) {
  const regex = /^([0-9]+)\,([0-9]+)$|^([0-9]+)$/gm

  value = String(value);
  if (!value)
    return true;

  if (!regex.test(value)) {
    return false
  }

  value = value.replace(',', '.');

  let real = parseFloat(value);

  return !isNaN(real) && isFinite(real) && real >= 0.01 && real <= 1000;
};
