export default function (value) {
  if (!value)
    return true;

  value = String(value);

  let matches = value.match(/(\,|\.)(\d{2})$/);

  if (matches && matches.length >= 3) {
    value = value.substr(0, value.length - 3).replace(/\D/g, '') + '.' + matches[2];
  }

  let real = parseFloat(value);

  return !isNaN(real) && isFinite(real) && real >= 0 && real <= 50000;
};
