<template>
  <div
    class="mui-accordion"
    :class="{ 'mui-accordion--active': isActive }"
    v-click-outside="close"
  >
    <slot
      name="header"
      :toggle='toggle'
      :isActive='isActive'
    >
      <div class="mui-accordion__header" @click="toggle">
        <h1 class="mui-accordion__title">{{ title }}</h1>
        <div class="mui-accordion__icon">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </div>
      </div>
    </slot>

    <div class="mui-accordion__overflow">
      <div class="mui-accordion__body">
        <slot
          name="body"
          :toggle='toggle'
          :isActive='isActive'
        >
          <div class="mui-accordion__body-content" v-html="text" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
// DEPENDENCIES
import vClickOutside from 'v-click-outside';

import uniqueIdHelper from "commons/js/mixins/uniqueIdHelper";

export default {
  name: "mui-accordion",

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [uniqueIdHelper],

  props: {
    title: {
      type: String,
      default: "",
    },

    text: {
      type: String,
      default: "",
    },

    closeOnClickOutside: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      uid: this.generateUUID(),
      isActive: false
    };
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;

      this.$emit("accordion-toggle", {
        status: this.isActive,
        uid: this.uid,
      });
    },

    close() {
      if (this.closeOnClickOutside) {
        this.isActive = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
$theme-neutral-dark = #3B3F51
$theme-neutral-shine = #ffffff
$theme-neutral-light = #c8cdda
$theme-primary = #00519d

$size-small = 14px
$size-normal = 16px
$size-big = 24px
$size-grand = 32px

$transition-default = 0.5s
$transition-fast = $transition-default / 2
$transition-slow = $transition-default * 2

.mui-accordion
  color $theme-neutral-dark
  background-color $theme-neutral-shine

  &__header
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    padding $size-normal $size-big
    cursor pointer
    border 1px solid $theme-neutral-light
    transition all 0.25s

    ^[0]--active &
      border-bottom-color $theme-neutral-shine

    &:hover
      border-color $theme-primary

    @media screen and (max-width 768px)
      padding $size-normal

  &__title
    font-size $size-normal
    color $theme-neutral-dark

    ^[0]--active &
      font-weight bold
      color $theme-primary

    @media screen and (max-width 768px)
      font-size $size-small

  &__icon
    color $theme-primary
    transition transform $transition-fast linear
    transform rotateZ(0)

    ^[0]--active &
      transform rotateZ(-180deg)

  &__body
    width 100%

    &-content
      font-size $size-small
      line-height 18px
      padding $size-normal $size-big $size-grand
      border 1px solid $theme-neutral-light
      border-top none

    a
      display inline
      font-size inherit
      line-height inherit
      font-weight inherit
      text-decoration underline
      color $theme-primary

      &:focus
        border 1px solid $theme-neutral-dark
        border-radius $size-nano

    i
      font-style italic

    strong
      font-weight bold

    p
      margin-bottom 12px
      &:last-child
        margin-bottom 0

    h1
      font-size $size-normal
      font-weight bold
      color $theme-primary

  &__overflow
    overflow-y hidden
    max-height 0
    transition max-height $transition-default cubic-bezier(0, 1, 0, 1)
    will-change auto

    ^[0]:hover
      will-change max-height

    ^[0]--active &
      max-height 100vh
      transition max-height $transition-slow ease-in-out

</style>
