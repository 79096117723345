<template>
  <main class="not-found">
    <app-header class="not-found__header" :user="user" />
    <section class="not-found__wrapper">
      <div class="not-found__text">
        <h1 class="not-found__title">Não encontramos a página</h1>
        <p class="not-found__subtitle">Por favor, tente novamente ou volte para o início.</p>
        <button
          class="mui-button mui-button--primary"
          @click="handleRedirect('/painel')"
          type="button"
        >
          Voltar para o início
        </button>
      </div>
      <div class="not-found__image">
        <img
          src="/images/ilustrations/404.svg"
          alt="Ilustração de uma porta aberta levando para um quarto escuro com uma caixa vazia abandonada"
        >
      </div>
    </section>
  </main>
</template>

<script>
import Header from './Header'

export default {
  props: ['user'],

  components: {
    'app-header': Header,
  },

  methods: {
    handleRedirect(path) {
      window.location.href = path;
    },
  }
}
</script>
