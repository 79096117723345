import Vue from 'vue';
import Vuex from 'vuex';
import globalAxios from 'axios';
import orderBy from 'lodash/orderBy';
import {newhomeUnloggedCalculatorAvailableShippingServices} from 'commons/js/ShippingDefinitions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sentRequest: false,
    menuModalOpened: false,
    logged: false,
    shippingValues: [],
    loadedValues: false,
    calculatorForm: {},
    form: {
      from: '',
      to: '',
      width: '12',
      height: '2',
      length: '17',
      weight: '0,3',
      insuranceValue: ''
    }
  },
  mutations: {
    toggleMenuMobile (state) {
      state.menuModalOpened = !state.menuModalOpened;
    },
    receivedValues (state, data) {
      state.shippingValues = data;
      state.loadedValues = true;
    },
    recalculate (state) {
      state.loadedValues = false;
    },
    requestSent (state) {
      state.sentRequest = true;
    },
    requestCompleted (state) {
      state.sentRequest = false;
    },
    UPDATE_FROM (state, value) {
      state.form.from = value;
    },
    UPDATE_TO (state, value) {
      state.form.to = value;
    },
    UPDATE_WIDTH (state, value) {
      state.form.width = value;
    },
    UPDATE_HEIGHT (state, value) {
      state.form.height = value;
    },
    UPDATE_LENGTH (state, value) {
      state.form.length = value;
    },
    UPDATE_WEIGHT (state, value) {
      state.form.weight = value;
    },
    UPDATE_INSURANCE_VALUE (state, value) {
      state.form.insuranceValue = value;
    }
  },
  actions: {
    calculateValues ({commit}, { from, to, width, height, length, insuranceValue, weight, reverse }) {
      commit('requestSent');

      /* Tratamento dos CEP`s */
      const fromTreated = from.replace(/\.|-/g, '');
      const toTreated = to.replace(/\.|-/g, '');

      /* Montagem da string de request */
      const mountedGet = `from=${fromTreated}&to=${toTreated}&width=${width}&weight=${weight}&height=${height}&length=${length}&insurance_value=${insuranceValue.replace(',', '.')}&services=${newhomeUnloggedCalculatorAvailableShippingServices.join(',')}`;

      /* Requisição da API */
      globalAxios
        .get(`/api/v2/calculator?${mountedGet}`)
        .then(res => {
          let filteredValues = res.data.filter(value => {
            return value.price = parseFloat(value.price);
          });

          filteredValues = res.data.filter(value => {
            if (value.company.id !== 1 || value.company.id !== 2 || value.company.id !== 14)
              if (!value.error)
                return value;
            else if (value.company.id === 1 || value.company.id === 2 || value.company.id === 14)
              return value;
          });

          filteredValues = orderBy(filteredValues, 'price');
          filteredValues = orderBy(filteredValues, 'error', 'desc');

          commit('receivedValues', filteredValues);

          commit('requestCompleted');
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    shippingValues (state) {
      return state.shippingValues;
    },
    withInsuranceValue (state) {
      return !!state.insuranceValue;
    }
  }
})
