<template>
  <transition name="modal-fade">
    <div class="modal-home">
      <PictureImage
        folderName="modals"
        fileName="modal-loggi.png"
        classNames="modal-home__image"
        :useIntersect="false"
      />
      <div
        class="modal-home__close-button"
        @click="closeModal">
        <ion-icon name="close"></ion-icon>
      </div>

      <div class="modal-home__content container">
        <h1 class="text--title text-color--neutral-dark">Transportadora Loggi no Melhor Envio: como fazer entregas</h1>

        <p class="modal-home__text text-color--neutral-dark text--normal">
          A transportadora Loggi usa tecnologia e foco no cliente para realizar entregas eficientes.
          Presente em todas as capitais brasileiras, a empresa movimenta mais de 500 mil pacotes por dia.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          E não é pra menos: além de uma estrutura digital que oferece rotas otimizadas e rastreamento de pedidos, a Loggi ainda atua aumentando sua capilaridade com:
        </p>
        <ul class="modal-home__list">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            entregas por motoboy;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            entregas por carros particulares;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            entregas por bicicletas.
          </li>
        </ul>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Com nove centros de distribuição próprios e mais de 400 transportadoras parceiras,
          a Loggi garante eficiência e agilidade para atender a todas as regiões brasileiras.
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="cube-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Transportadora Loggi no Melhor Envio</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A Loggi é uma das parceiras do Melhor Envio, a principal solução de intermediação de frete do Brasil.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Isso significa que a postagem das encomendas pode acontecer em em uma das muitas unidades dos
          centralizadores de envio espalhados por todo o Brasil.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Fretes baratos com a Loggi</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Utilizando a Loggi pelo Melhor Envio, você consegue emitir etiquetas por preços
          até 80% mais baixos que no balcão da transportadora.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Como enviar pela Loggi usando o Melhor Envio?</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Após <a href="/cadastre-se">fazer um cadastro simples e gratuito</a>
          na plataforma do Melhor Envio, você está pronto para enviar com a Loggi. Basta acessar a
          <a href="https://app.melhorenvio.com.br/calculadora" target="_blank" rel="noopener noreferrer">Calculadora de Fretes</a>
          e fazer a cotação com as informações do seu pacote.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Dimensões de cargas da Loggi</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A dimensões das cargas que podem ser enviadas pela Loggi são:
        </p>
        <ul class="modal-home__list">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Medida máxima: 100 cm. A soma das medidas não pode ultrapassar 200 cm;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Peso máximo: 30 kg;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Não há dimensões nem peso mínimos.
          </li>
        </ul>

        <p class="modal-home__text text-color--neutral-dark text--normal">
          Objetos com formatos não mecanizáveis, ou seja, diferentes do padrão cúbico e retangular,
          podem gerar taxação extra. Então, para economizar a dica é sempre colocar os envios em caixas,
          mesmo que sejam itens como:
        </p>
        <ul class="modal-home__list">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            sacos de grãos;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            sacos de travesseiros ou roupas;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            embrulhos em formato irregular;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            objetos em que uma das dimensões ultrapassem 70 cm.
          </li>
        </ul>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="bag-check-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Loggi Coleta</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Loggi Coleta é um serviço que facilita a rotina logística de quem vende online. Além de
          <strong>otimizar tempo e recursos com o deslocamento até o ponto de postagem, o agendamento da coleta
          é feito de maneira automatizada.</strong>
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Você só precisa selecionar a modalidade “coleta” no momento de gerar a etiqueta
          e o agendamento vai ser feito simultaneamente.
        </p>
        <ul class="modal-home__list">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Etiquetas geradas até às 11h são coletadas no mesmo dia;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Etiquetas geradas após às 11h são coletadas no próximo dia útil;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Etiquetas geradas em finais de semana têm coletas agendadas para o próximo dia útil.
          </li>
        </ul>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>Atenção:</strong> seus pacotes nem sempre serão coletados por veículos da Loggi.
          A transportadora conta com uma ampla rede de motoboys e motoristas particulares credenciados.
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="map-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Rastreio Loggi</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Você e seu cliente podem rastrear os envios feitos pela Loggi de duas maneiras: pelo site datransportadora
          ou pelo <a href="https://melhorrastreio.com.br/" target="_blank" rel="noopener noreferrer">Melhor Rastreio</a>,
          ferramenta gratuita do Melhor Envio que centraliza todos os seus monitoramentos de pacotes em um único painel.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Rastreamento no site da Loggi</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Para fazer o rastreamento de seus objetos pelo site da Loggi, siga esses 3 passos:
        </p>
        <ol class="modal-home__list modal-home__list--number">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Acesse a página oficial da empresa;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Clique no menu <strong>Rastrear</strong>;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Digite o código de rastreamento e clique em <strong>acompanhar pedido</strong>.
          </li>
        </ol>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A solução é muito efetiva para pedidos simples, entretanto, para acompanhar em tempo real o
          deslocamento de várias encomendas, a melhor opção é o Melhor Rastreio.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Rastreamento Loggi com o Melhor Rastreio</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          O Melhor Rastreio foi desenvolvido para facilitar a visualização de suas encomendas.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Por meio de um painel centralizado é possível acompanhar tudo o que acontece com seus envios.
          Além disso, é possível ativar uma opção para receber notificações por e-mail.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Para fazer o rastreamento, basta:
        </p>

        <ol class="modal-home__list modal-home__list--number">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Acessar sua conta do <a href="https://app.melhorrastreio.com.br/app/" target="_blank" rel="noopener noreferrer">Melhor Rastreio</a>
            para rastrear pedidos da Loggi;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Selecionar o campo <strong>Rastreie sua encomenda</strong>, no painel inicial;
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Pronto! Na sua tela vão aparecer todas as atualizações de rastreamento.
          </li>
        </ol>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="car-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Loggi fácil</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          O Loggi Fácil é o serviço de entregas para pessoas físicas da Loggi. Nele, o cliente não precisa se
          deslocar até uma agência física da empresa, já que todo o processo é feito pelo aplicativo.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A encomenda é retirada na casa do cliente e entregue no destino. A Loggi fica responsável por embalar
          o produto, se o cliente não tiver feito isso em casa, e coletar o material no dia e horário agendados.
        </p>

        <div class="modal-home__cta">
          <a class="cta" href="/">
            Saiba mais vantagens do Melhor Envio
            <ion-icon name="arrow-forward-outline" class="icon"></ion-icon>
          </a>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import PictureImage from '../Common/Picture.vue';

export default {
  components: {
    PictureImage
  },

  methods: {
    closeModal () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

