export const companiesLogos = [
  {
    imagePath: setImagePath('Transportadoras','Correios_v2.png'),
    alt: 'Logo dos Correios',
    url: '/calcular-frete-correios'
  },
  {
    imagePath: setImagePath('Transportadoras','Jadlog_v2.png'),
    alt: 'Logo da Jadlog',
    url: '/transportadora-jadlog'
  },
  {
    imagePath: setImagePath('Transportadoras','Azul_Express_v2.svg'),
    alt: 'Logo da Azul Cargo Express'
  },
  {
    imagePath: setImagePath('Transportadoras','Latam_v2.svg'),
    alt: 'Logo da LATAM Cargo'
  },
  {
    imagePath: setImagePath('Transportadoras','Loggi_v2.svg'),
    alt: 'Logo da Loggi',
    url: '/transportadora-loggi-entrega'
  },
  {
    imagePath: setImagePath('Transportadoras','buslog_logo.svg'),
    alt: 'Logo da Buslog'
  },
  {
    imagePath: setImagePath('Transportadoras','Jet_v2.svg'),
    alt: 'Logo da J&T'
  }
]

/**
 * Returns the image path for the given image folder and name
 * @returns {string} - the image path
*/
function setImagePath(folderName, imageName) {
  return require(`../assets/img/Desktop/Carrocel/${folderName}/${imageName}`)
}
