import {nanoid} from 'nanoid';

export default {
	methods: {

		/**
		 * Uses the nanoid library to generate an universally unique identifier.
		 * @returns {string}
		 */
		generateUUID(size = 21) {
			return nanoid(size);
		}
	}
};
