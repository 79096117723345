import { render, staticRenderFns } from "./EasyToUse.vue?vue&type=template&id=2874d584&scoped=true"
var script = {}
import style0 from "./EasyToUse.vue?vue&type=style&index=0&id=2874d584&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2874d584",
  null
  
)

export default component.exports