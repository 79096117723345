<template>
  <div>
    <div v-if="shouldRender">
      <picture v-if="!isSVG">
        <!-- Source para resolução menor em WebP -->
        <source :srcset="mobileImages.webp" type="image/webp" media="(max-width: 767px)">
        <!-- Source para resolução menor em PNG -->
        <source :srcset="mobileImages.original" :type="mimeType" media="(max-width: 767px)">

        <!-- Source para resolução maior em WebP -->
        <source :srcset="desktopImages.webp" type="image/webp">
        <!-- Source para resolução maior em PNG -->
        <source :srcset="desktopImages.original" :type="mimeType">
        <img
          :class="classNames"
          :src="formatedURL()"
          :alt="altText"
        />
      </picture>
      <picture v-else-if="hasMobileSvg">
        <!-- Source para resolução menor (mobile) -->
        <source  :srcset="mobileSVG" media="(max-width: 767px)">
        <!-- Source para resolução maior (desktop) -->
        <source :srcset="desktopSVG" media="(min-width: 768px)">
        <img
          :class="classNames"
          :src="formatedURL()"
          :alt="altText"
        />
      </picture>
      <img v-else
        :class="classNames"
        :src="formatedURL()"
        :alt="altText"
      />
    </div>
    <Intersector v-if="useIntersect" :options="intersectOptions" @intersect="intersected" />
  </div>
</template>

<script>
import Intersector from './Intersector'

export default {
  components: {
    Intersector,
  },
  props: {
    fileName: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      required: false
    },
    folderName: {
      type: String,
      required: false
    },
    classNames: {
      type: String,
      required: false
    },
    useIntersect: {
      type: Boolean,
      default: true
    },
    intersectOptions: {
      type: Object,
      default: () => {}
    },
    hasMobileSvg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldRender() {
      if (!this.useIntersect) {
        return true;
      }

      return this.isIntersected;
    },
    imageExtension() {
      return this.fileName.split('.').pop();
    },
    imageName() {
      return this.fileName.slice(0, this.fileName.lastIndexOf('.'));
    },
    mobileImages() {
      return {
        webp: this.formatedURL(`${this.imageName}-mob.webp`),
        original: this.formatedURL(`${this.imageName}-mob.${this.imageExtension}`),
      }
    },
    desktopImages() {
      return {
        webp: this.formatedURL(`${this.imageName}.webp`),
        original: this.formatedURL(this.fileName),
      }
    },
    mobileSVG() {
      return this.formatedURL(`${this.imageName}-mob.svg`);
    },
    desktopSVG() {
      return this.formatedURL(`${this.imageName}.svg`);
    },
    mimeType() {
      const type = (this.imageExtension) === 'jpg' ? 'jpeg' : this.imageExtension;

      return `image/${type}`;
    },
    isSVG() {
      return this.imageExtension === 'svg';
    }
  },
  data() {
    return {
      isIntersected: false
    }
  },
  methods: {
    intersected() {
      this.isIntersected = true;
    },
    formatedURL(fileName = this.fileName) {
      return `/images/${this.folderName}/${fileName}`;
    }
  }
}
</script>
