<template>
  <div class="deposition-item">
    <div class="deposition-item__wrapper">
      <div class="deposition-item__header">
        <div class="deposition-item__image">
          <PictureImage
            folder-name="depoimentos"
            :file-name="image"
            :alt-text="company.name"
            :use-intersect="false"
          />
        </div>
        <div class="deposition-item__partner">
          <p class="text--normal text-color--neutral-dark">{{ name }}</p>
          <a class="text-color--primary" target="_blank" rel="noopener noreferrer nofollow" :href="$sanitizeUrl(company.url)" :title="company.name">{{ company.name }}</a>
        </div>
      </div>
      <div class="deposition-item__body">
        <p class="deposition-item__message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.deposition-item
  &__wrapper
    padding 0 8px

    @media screen and (min-width 768px)
      width auto
      padding 0 98px

    @media screen and (min-width 1024px)
      max-width 1000px
      box-sizing border-box
      margin 0 auto
      padding 0 46px 0 60px

  &__header
    display flex
    justify-content space-between
    align-items center
    gap 16px
    padding-left 18px

    @media screen and (min-width 768px)
      flex-direction column
      gap 0
      padding-left 0

  &__image
    width 60px
    height 60px
    border-radius 9999px
    overflow hidden
    position relative

    @media screen and (min-width 1024px)
      width 80px
      height 80px

    >>> img
      max-width 100%
      display block
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

  &__partner
    flex 1

    @media screen and (min-width 768px)
      text-align center

    a
    .text--normal
      line-height 24px

      @media screen and (min-width 768px)
        line-height 18px

    a
      display inline-block

    .text--normal
      font-weight 700

      @media screen and (min-width 768px)
        margin 8px 0

  &__body
    margin-top 18px
    padding-left 18px
    position relative

    @media screen and (min-width 768px)
      margin-top 24px

    &::before
      content "“"
      color #3B3F51
      font-size 40px
      font-weight 500
      line-height 0.45
      position absolute
      top 4px
      left 0

  &__message
    font-size 14px
    font-style normal
    font-weight 400
    line-height 18px

    @media screen and (min-width 1024px)
      font-size 16px
      line-height 20px
</style>
