<template>
  <div
    id="menu-modal"
    :class="{menuMobileOpened: menuOpened}"
  >
    <transition name="modal-menu">
      <div
        v-if="menuOpened"
        class="menu-modal"
        @click="handleClick"
      >
        <div class="menu-modal-content">
          <div class="modal-center">
            <ul class="actions-modal">
              <li>
                <a href="https://menv.io/parceiros" target="_blank" rel="noopener noreferrer" class="action-modal">parceiros</a>
              </li>
              <li>
                <a :href="$sanitizeUrl(supportPublicDashUrl)" target="_blank" rel="noopener noreferrer" class="action-modal">suporte</a>
              </li>
              <li>
                <a href="https://melhorenvio.com.br/blog" target="_blank" rel="noopener noreferrer" class="action-modal">blog</a>
              </li>
              <li>
                <a href="https://melhorrastreio.com.br" target="_blank" rel="noopener noreferrer" class="action-modal">rastreio</a>
              </li>
            </ul>
            <div class="button-box">
              <a href="/painel" class="btn-border">entrar</a>
            </div>
            <div class="button-box">
              <a href="/cadastre-se" class="btn-border -full-blue">cadastrar</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportPublicDashUrl: ''
    }
  },

  mounted() {
    this.supportPublicDashUrl = MEConfig.get('dash.url') + '/suporte';
  },

  computed: {
    menuOpened () {
      return this.$store.state.menuModalOpened;
    }
  },

  methods: {
    handleClick (event) {
      if (event.target.className === 'menu-modal') {
        this.closeModal();
        this.$store.commit('toggleMenuMobile');
      }
    }
  }
}
</script>
