<template>
  <transition name="modal-fade">
    <div class="modal-home">
      <PictureImage
        folderName="modals"
        fileName="modal-correios.png"
        classNames="modal-home__image"
        :useIntersect="false"
      />
      <div
        class="modal-home__close-button"
        @click="closeModal">
        <ion-icon name="close"></ion-icon>
      </div>

      <div class="modal-home__content container">
        <h1 class="text--title text-color--neutral-dark">Calcular frete Correios: tenha preços e prazos mais vantajosos com a calculadora do Melhor Envio</h1>

        <p class="modal-home__text text-color--neutral-dark text--normal">
          Além de ser uma tarefa complicada, <router-link to="/">calcular frete nos Correios</router-link> pode
          não apresentar os resultados mais vantajosos para todo tipo de remessa,
          principalmente nos casos de vendas online, onde preço e prazo de entrega precisam
          ser o mais atrativo possível para os clientes.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Para ajudar lojistas de todos os tamanhos, a plataforma de gestão e cotação de frete Melhor Envio,
          do Grupo Locaweb, oferece gratuitamente a sua calculadora de frete.
          A ferramenta é totalmente automatizada e pensada para facilitar a venda de quem empreende online.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Com a <router-link to="/">Calculadora de Frete do Melhor Envio</router-link>,
          em poucos cliques é possível <strong>conferir os preços e prazos oferecidos pelos Correios,
            saber se essas condições são as melhores para cada tipo de entrega</strong>
            e ainda ter acesso a <strong>fretes mais baratos</strong>.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A seguir, descubra como realizar essa simulação dos Correios de forma descomplicada usando o Melhor Envio.
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="help-circle-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Como calcular o frete dos Correios</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Como dissemos, <strong>fazer a simulação de frete dos Correios é mais simples
          e econômico com o serviço gratuito do Melhor Envio</strong>.
          Com a Calculadora de Frete, contratar e gerar etiquetas de envio é uma tarefa super rápida.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Além disso, com o seu serviço de comparação de preço, é possível ter certeza
          se o frete oferecido pelos Correios é realmente o mais vantajoso para a sua remessa,
          já que <strong>o resultado da Calculadora compara preços e prazos dos Correios
          com os das principais transportadoras particulares do país</strong>.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Para fazer o cálculo, basta seguir esse passo a passo:
        </p>
        <ol class="modal-home__list modal-home__list--number">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Primeiro, caso você ainda não possua,
            <a href="/cadastre-se" title="faça o cadastro gratuito na plataforma">faça o cadastro gratuito na plataforma</a>.
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Com o cadastro realizado, faça
            <a href="/login" title="faça login no Melhor Envio">login no Melhor Envio</a>
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            <strong>Clique no ícone da calculadora</strong> localizado no topo e na barra lateral na página.
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Informe o CEP de origem, CEP de destino, dimensões do pacote, valor segurado e clique em <strong>"calcular"</strong>.
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Na tela seguinte, basta escolher a transportadora que está oferecendo melhor preço e tempo de entrega para o seu pedido e clicar em <strong>"selecionar"</strong>.
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Depois, informe o endereço completo do remetente e do destinatário, selecione o local de postagem, anexe a nota fiscal ou declaração de conteúdo e clique no botão <strong>"pagar agora"</strong> ou <strong>"adicionar ao carrinho"</strong>, caso queira contratar mais fretes.
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Prontinho! Por fim, é só realizar o pagamento do pedido, aguardar a confirmação e imprimir a etiqueta de frete.
          </li>
        </ol>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Agora é só embrulhar o pacote, colar a etiqueta, anexar a nota fiscal
          (ou declaração de conteúdo) na parte externa da embalagem e levar a encomenda
          até uma unidade dos Correios ou do Melhor Ponto escolhida para realizar a postagem.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>
            Atenção: Lembrando mais uma vez que não é preciso pagar nada para calcular
            frete dos Correios com o Melhor Envio e nem mesmo para integrar a Calculadora de Fretes
            diretamente nas páginas dos produtos de sua loja virtual.
          </strong>
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>
            Ou seja, a sua loja só vai pagar o valor dos fretes contratados dentro da plataforma.
            Por isso, <a href="/cadastre-se" title="faça o seu cadastro grátis no Melhor Envio">faça o seu cadastro grátis no Melhor Envio</a>
            e comece a aproveitar essa vantagem competitiva a favor do seu negócio, sem gastar nada a mais.
          </strong>
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="cash-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">
            Quanto custa para enviar uma encomenda pelos Correios? Contrate Sedex e PAC mais barato
          </h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Quem contrata fretes dos Correios usando o Melhor Envio, muitas vezes,
          tem acesso a preços mais baixos que os praticados diretamente no balcão das agências.
          E o melhor, para ter acesso a esses preços mais vantajosos,
          não é preciso fazer um contrato direto com os Correios.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Isso porque o Melhor Envio negocia diretamente com os Correios para garantir valores
          mais baixos para os seus usuários.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <a href="https://www.melhorponto.com.br/unidades/" rel="noopener noreferrer" target="_blank" title="Unidades do Melhor Ponto">Encontre o ponto de postagem mais próximo de você!</a>
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="flash-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Faça simulação de frete nos Correios grátis</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Comece agora mesmo a utilizar a Calculadora de Frete para saber o frete dos Correios de forma descomplicada.
          Para isso, faça o seu <a href="/cadastre-se" rel="noopener noreferrer" title="cadastro grátis no Melhor Envio">cadastro grátis no Melhor Envio</a>.
        </p>

        <div class="modal-home__cta">
          <a class="cta" href="/">
            Saiba mais vantagens do Melhor Envio
            <ion-icon name="arrow-forward-outline" class="icon"></ion-icon>
          </a>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import PictureImage from '../Common/Picture.vue';

export default {
  components: {
    PictureImage
  },

  methods: {
    closeModal () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

