<template>
  <section id="calculator">
    <div class="calculator-box">
      <calculator-form v-if="!$store.state.loadedValues" />
      <calculator-table v-else />
    </div>
  </section>
</template>

<script>
import CalculatorForm from './CalculatorForm';
import CalculatorTable from './CalculatorTable';

export default {
  components: {
    'calculator-form': CalculatorForm,
    'calculator-table': CalculatorTable
  },
}
</script>

<style lang="stylus" scoped>
.form-external
  max-width 870px
  padding 0 30px
</style>
