import Vue from 'vue';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import {sanitizeUrl} from "@braintree/sanitize-url";
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';

import router from './router';
import store from './store';

import PictureImage from './components/Common/Picture.vue'

import AppHome from './App.vue';

VTooltip.options.defaultClass = 'vue-v-tooltip-theme';
VTooltip.options.defaultTemplate = '<div class="v-tooltip" role="tooltip"><div class="v-tooltip-arrow"></div><div class="v-tooltip-inner"></div></div>';
VTooltip.options.defaultArrowSelector = '.v-tooltip-arrow, .v-tooltip__arrow';
VTooltip.options.defaultInnerSelector = '.v-tooltip-inner, .v-tooltip__inner';
VTooltip.options.defaultLoadingClass = 'v-tooltip-loading';
VTooltip.options.defaultTrigger = 'hover focus click';
VTooltip.options.defaultHideOnTargetClick = false;
VTooltip.options.autoHide = false;
VTooltip.options.defaultDelay = {show: 100, hide: 200};

VTooltip.options.popover.defaultClass = 'vue-popover-theme';
VTooltip.options.popover.defaultBaseClass = 'v-tooltip popover';
VTooltip.options.popover.defaultWrapperClass = 'v-tooltip-wrapper';
VTooltip.options.popover.defaultInnerClass = 'v-tooltip-inner popover-inner';
VTooltip.options.popover.defaultArrowClass = 'v-tooltip-arrow popover-arrow';

Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('VPopover', VPopover);

Vue.prototype.$sanitizeUrl = sanitizeUrl;

Vue.config.ignoredElements = [/^ion-/u];

Vue.use(Vuelidate);

Vue.component('PictureImage', PictureImage);

axios.defaults.baseURL = MEConfig.get('app.url');
axios.defaults.headers.get['Accept'] = 'application/json';

const interceptor = axios.interceptors.request.use((config) => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  config.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  return config
});

axios.interceptors.request.use(interceptor);

new Vue({
  el: '#app',
  components: { AppHome },
  router,
  store
});
