<template>
  <div>
    <Header />
    <Hero />
    <BigNumbers />
    <EasyToUse />
    <PartnersBadges />
    <WhatWeDoForYou />
    <ProductWrapper
      theme="melhor-ponto"
      :data="melhorPontoData"
    >
      <template v-slot:cta>
        <a
          class="cta"
          href="https://www.melhorponto.com.br/unidades/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver unidade mais próxima
          <ion-icon name="arrow-forward-outline" class="icon"></ion-icon>
        </a>
      </template>
    </ProductWrapper>
    <ProductWrapper
      theme="melhor-rastreio"
      :data="melhorRastreioData"
      is-reverse
    />
    <Depositions :depositions="depositions" />
    <Faq />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header'
import Hero from '../components/Hero'
import BigNumbers from '../components/BigNumbers.vue'
import EasyToUse from '../components/EasyToUse.vue'
import PartnersBadges from '../components/PartnersBadges'
import ProductWrapper from '../components/ProductWrapper.vue'
import WhatWeDoForYou from '../components/WhatWeDoForYou'
import Depositions from '../components/Depositions/Depositions'
import Faq from '../components/Faq.vue'
import Footer from '../components/Footer'

import depositions from '../components/Depositions/data.json'
import { melhorPonto, melhorRastreio } from '../config/products'

export default {
  props: [
    'auth'
  ],

  components: {
    Hero,
    Header,
    BigNumbers,
    EasyToUse,
    PartnersBadges,
    ProductWrapper,
    WhatWeDoForYou,
    Depositions,
    Faq,
    Footer
  },

  data() {
    return {
      depositions,
      melhorPontoData: melhorPonto,
      melhorRastreioData: melhorRastreio
    }
  }
}
</script>
