import debounce from 'lodash/debounce';

const BREAKPOINT_MD = 720;
const BREAKPOINT_LG = 1280;
const BREAKPOINT_MD_V2 = 940;

/**
 * Mixin to detect if the current viewport matches
 * with mobile resolution.
 */
export default {
  data() {
    return {

      /**
       * Variable to hold the Boolean value returned by
       * the checkResolution method.
       */
      isMobile: null,

      /**
       * Variable to hold the Boolean value returned by
       * the checkResolution method.
       */
      isTablet: null,

      /**
       * Used to set - or not - the listeners on the resize event.
       * Set it to false inside your component data
       * if you want to turn off the listeners.
       */
      hasListeners: true,

      /**
       * Variable used to adjust the styles for legacy device resolutions
       */
      isMobileV2: null
    };
  },

  computed: {
    isDesktop() {
      return !this.isMobile && !this.isMobileV2 && !this.isTablet;
    }
  },

  created() {
    this.isMobile = this.checkResolution(BREAKPOINT_MD);
    this.isTablet = this.checkResolution(BREAKPOINT_LG);
    this.isMobileV2 = this.checkResolution(BREAKPOINT_MD_V2);

    if (this.hasListeners) {
      window.addEventListener('resize', debounce(this.onResize, 300));
    }
  },

  destroyed() {
    if (this.hasListeners) {
      window.removeEventListener('resize', debounce(this.onResize, 300));
    }
  },

  methods: {

    /**
     * Returns if the current viewport size matches with
     * the passed breakpoint value.
     * @param {Number} breakpoint - the breakpoint width
     * @returns {Boolean}
     */
    checkResolution(breakpoint) {
      const {matchMedia} = window;

      return matchMedia(`(max-width: ${breakpoint}px)`).matches;
    },

    /**
     * Method to be bind to the resize event.
     */
    onResize() {
      /**
       * Update our variables inside data property.
       */
      this.isMobile = this.checkResolution(BREAKPOINT_MD);
      this.isTablet = this.checkResolution(BREAKPOINT_LG);
      this.isMobileV2 = this.checkResolution(BREAKPOINT_MD_V2);
    }
  }
};
