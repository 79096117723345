<template>
  <div class="product-wrapper" :class="getClasses">
    <div class="product-wrapper__main-content">
      <div class="product-wrapper__image">
        <PictureImage
          folderName="products"
          :fileName="data.imageName"
          :altText="data.imageAlt"
          classNames="product-wrapper__picture"
          :intersectOptions="{
            rootMargin: '200px'
          }"
        />
      </div>
      <div class="product-wrapper__content">
        <h3 class="text--big text-color--white"> {{ data.title }}</h3>
        <p class="secondary-text--big text-color--white">
          {{ data.description }}
        </p>
      </div>
    </div>
    <div class="product-wrapper__inner-content">
      <slot
        name="inner-content"
        :data="data"
      >
        <div class="product-wrapper__inner-content--item" v-for="icon in data.icons">
          <img :src="icon.iconPath" alt="" role="presentation" width="50px" height="50px">
          <p class="secondary-text--big text-color--neutral-dark">{{ icon.text }}</p>
        </div>
      </slot>
    </div>
    <div
      v-if="$slots.cta"
      class="product-wrapper__cta"
    >
      <slot name="cta"></slot>
    </div>
  </div>
</template>

<script>
import PictureImage from './Common/Picture.vue';

export default {
  components: {
    PictureImage
  },

  props: {
    theme: {
      type: String,
      required: true
    },

    data: {
      type: Object,
      required: true
    },

    isReverse: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getClasses() {
      const {isReverse} = this;

      return [
        `product-wrapper--${this.theme}`,
        isReverse && 'product-wrapper--reverse'
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
.product-wrapper
  display flex
  flex-direction column
  align-items center

  &__main-content
    display flex
    align-items center
    flex-direction column
    margin 0 16px
    padding-top 60px
    max-width 1024px

    @media screen and (min-width 1048px)
      margin 0 auto
      flex-direction row

      ^[0]--reverse &
        flex-direction row-reverse

  &__image
    z-index 2
    display flex
    flex-direction column
    align-items center
    justify-content flex-end
    border-radius 100%
    width 250px
    height 250px
    border 8px solid white

    ^[0]--melhor-ponto &
      background-color #A43CDE

    ^[0]--melhor-rastreio &
      background-color #38D372

    @media screen and (min-width 1048px)
      width 350px
      height 350px

  >>> &__picture
    margin-bottom -2px

    ^[0]--melhor-ponto &
      margin-left 15px
      width 280px
      height 300px

    ^[0]--melhor-rastreio &
      width 250px
      height 300px

    @media screen and (min-width 1048px)
      ^[0]--melhor-ponto &
        width 380px
        height 420px

      ^[0]--melhor-rastreio &
        width 350px
        height 420px

  &__content
    text-align center
    padding 60px 32px 40px 32px
    margin-top -40px
    border-radius 4px

    ^[0]--melhor-ponto &
      background linear-gradient(44deg, #A43CDE 0%, #7E02DE 100%)

    ^[0]--melhor-rastreio &
      background linear-gradient(44deg, #24B65C 0%, #38D372 100%)


    @media screen and (min-width 1048px)
      text-align left
      margin-top 0
      padding 60px 48px 60px 100px
      margin-left -60px

      ^[0]--reverse &
        padding 60px 100px 60px 48px
        margin-left 0
        margin-right -60px

  &__inner-content
    margin 0 16px
    display grid
    grid-template-rows 1fr
    justify-content center
    gap 24px
    padding 24px 0

    @media screen and (min-width 768px)
      grid-template-columns 1fr 1fr 1fr
      max-width calc(1024px - 120px)
      padding 24px 60px
      margin 0 auto
      flex-direction row

    &--item
      max-width 75%
      margin 0 auto
      text-align center
      display flex
      flex-direction column
      align-items center
      gap 8px

      @media screen and (min-width 768px)
        max-width 100%

  &__cta
    margin-top 24px
</style>
