<template>
  <div class="container-calculator">
    <h2 class="text--big text-color--primary">Calculadora de fretes simplificada</h2>

    <spinner v-if="sentRequest"/>

    <form v-else class="me-form" @submit.prevent="onSubmit">
      <p class="text-color--neutral-dark secondary-text--big">
        Faça sua cotação nas principais transportadoras do país
      </p>
      <div class="row-calculator-form">
        <div class="formBox cep-input">
          <fieldset
            :class="{ active: from, error: v$.from.$error, focus: focus.from }"
            class="formLine"
          >
            <legend class="sr-only">CEP de origem</legend>
            <div class="inputBox">
              <img
                v-if="stateStatus.from"
                :src="require(`../../assets/img/flags/${state.from}.png`)"
                class="cep-flag"
                alt="Bandeira Estado"
              >
              <label :for="'from'">CEP de origem</label>
              <input
                v-mask="'99.999-999'"
                id="from"
                ref="from"
                v-model="from"
                type="text"
                inputmode="numeric"
                name="from"
                @focus="iptFocus('from', $event)"
                @blur="iptBlur('from', $event)"
                @change="checkCEP(from, 'from')"
              >
              <p class="errorText">
                {{ v$.from.minLength ? 'Campo obrigatório!' : 'Digite um CEP válido' }}
              </p>
            </div>
          </fieldset>
        </div>

        <div class="formBox cep-input">
          <fieldset
            :class="{ active: to, error: v$.to.$error, focus: focus.to }"
            class="formLine"
          >
            <legend class="sr-only">CEP de destino</legend>
            <div class="inputBox">
              <img
                v-if="stateStatus.to"
                :src="require(`../../assets/img/flags/${state.to}.png`)"
                class="cep-flag"
                alt="Bandeira Estado"
              >
              <label :for="'to'">CEP de destino</label>
              <input
                v-mask="'99.999-999'"
                id="to"
                ref="to"
                v-model="to"
                type="text"
                inputmode="numeric"
                name="to"
                @focus="iptFocus('to', $event)"
                @blur="iptBlur('to', $event)"
                @change="checkCEP(to, 'to')"
              >
              <p class="errorText">
                {{ v$.to.minLength ? 'Campo obrigatório!' : 'Digite um CEP válido' }}
              </p>
            </div>
          </fieldset>
        </div>

      </div>

      <div class="calc-input-row">
        <div class="formBox calc-input">
          <fieldset
            :class="{ active: height, error: v$.height.$error, focus: focus.height }"
            class="formLine"
          >
            <legend class="sr-only">Altura do pacote</legend>
            <div class="inputBox">
              <label :for="'height'">Altura</label>
              <input
                id="height"
                ref="height"
                v-model="height"
                type="text"
                inputmode="numeric"
                name="height"
                @focus="iptFocus('height', $event)"
                @blur="iptBlur('height', $event)"
                v-mask="'999'"
              >
              <span v-if="focus.height || height " class="span-before" @click="setFocus('height')">
                cm
              </span>
            </div>
            <p class="errorTextCalculator">
              {{ !v$.height.realDimension ? 'Dimensão incorreta!' : 'Campo Obrigatório!' }}
            </p>
          </fieldset>
        </div>
        <div class="formBox calc-input">
          <fieldset
            :class="{ active: width, error: v$.width.$error, focus: focus.width }"
            class="formLine"
          >
            <legend class="sr-only">Largura do pacote</legend>
            <div class="inputBox">
              <label :for="'width'">Largura</label>
              <input
                id="width"
                ref="width"
                v-model="width"
                type="text"
                inputmode="numeric"
                name="width"
                @focus="iptFocus('width', $event)"
                @blur="iptBlur('width', $event)"
                v-mask="'999'"
              >
              <span v-if="focus.width || width " class="span-before" @click="setFocus('width')">
                cm
              </span>
            </div>
            <p class="errorTextCalculator">
              {{ !v$.width.realDimension ? 'Dimensão incorreta!' : 'Campo Obrigatório!' }}
            </p>
          </fieldset>
        </div>
        <div class="formBox calc-input">
          <fieldset
            :class="{ active: length, error: v$.length.$error, focus: focus.length }"
            class="formLine"
          >
            <legend class="sr-only">Comprimento do pacote</legend>
            <div class="inputBox">
              <label :for="'length'">Comprimento</label>
              <input
                id="length"
                ref="length"
                v-model="length"
                type="text"
                inputmode="numeric"
                name="length"
                @focus="iptFocus('length', $event)"
                @blur="iptBlur('length', $event)"
                v-mask="'999'"
              >
              <span
                v-if="focus.length || length "
                class="span-before"
                @click="setFocus('length')"
              >
                cm
              </span>
            </div>
            <p class="errorTextCalculator">{{ !v$.length.realDimension ? 'Dimensão incorreta!' : 'Campo Obrigatório!' }}</p>
          </fieldset>
        </div>
        <div class="formBox calc-input">
          <fieldset
            :class="{ active: weight, error: v$.weight.$error, focus: focus.weight }"
            class="formLine"
          >
            <legend class="sr-only">Peso do pacote</legend>
            <div class="inputBox">
              <label :for="'weight'">Peso</label>
              <input
                id="weight"
                ref="weight"
                v-model="weight"
                type="text"
                inputmode="numeric"
                name="weight"
                @keypress="stripTheGarbage($event, 'weight')"
                @focus="iptFocus('weight', $event)"
                @blur="iptBlur('weight', $event)"
              >
              <span
                v-if="focus.weight || weight "
                class="span-before"
                @click="setFocus('weight')"
              >
                kg
              </span>
            </div>
            <p class="errorTextCalculator">{{ !v$.weight.realWeight ? 'Valor inválido!' : 'Campo Obrigatório!' }}</p>
          </fieldset>
        </div>
        <div class="formBox calc-input-last">
          <fieldset
            :class="{ active: price, error: v$.price.$error, focus: focus.insuranceValue }"
            class="formLine"
          >
            <legend class="sr-only">Valor da carga</legend>
            <div class="inputBox">
              <label :for="'insuranceValue'">Valor da carga</label>
              <input
                v-mask="'money'"
                id="insuranceValue"
                ref="insuranceValue"
                v-model="price"
                type="text"
                inputmode="numeric"
                name="insuranceValue"
                @focus="iptFocus('insuranceValue', $event)"
                @blur="iptBlur('price', $event)"
              >
              <span
                v-if="focus.insuranceValue || price "
                class="span-after"
                @click="setFocus('insuranceValue')"
              >
                R$
              </span>
            </div>
            <p class="errorTextCalculator">Valor inválido!</p>
          </fieldset>
        </div>
      </div>
      <div class="button-box">
        <button class="button button--primary button--full" type="submit" id="calculate">
          Calcular
        </button>
      </div>
    </form>

  </div>
</template>

<script>
import { getOffsetTop } from '../Common/utils';
import PostalCodeZones from '../../assets/files/postal_code_zones.json'
import ArrowCalculator from '../../assets/img/svg/arrow-calculator.svg'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import realDimension from '../../validators/realDimension'
import realWeight from 'commons/js/validators/realWeight';
import realMoney from 'commons/js/validators/realMoney';
import AwesomeMask from 'awesome-mask'
import Spinner from '../Spinner'

export default {
  setup () {
    return {
      v$: useVuelidate()
    }
  },

  components: {
    'spinner': Spinner,
  },
  directives: {
    'mask': AwesomeMask
  },

  data () {
    return {
      focus: {
        from: false,
        to: false,
        width: false,
        height: false,
        length: false,
        weight: false,
        insuranceValue: false
      },
      state: {
        from: 'RS',
        to: 'RS'
      },
      stateStatus: {
        from: false,
        to: false
      },
      reverse: false,
      price: '',
      arrow: ArrowCalculator
    }
  },
  computed: {
    sentRequest () {
      return this.$store.state.sentRequest;
    },
    from: {
      get () {
        return this.$store.state.form.from;
      },
      set (val) {
        this.$store.commit('UPDATE_FROM', val);
      }
    },
    to: {
      get () {
        return this.$store.state.form.to;
      },
      set (val) {
        this.$store.commit('UPDATE_TO', val);
      }
    },
    width: {
      get () {
        return this.$store.state.form.width;
      },
      set (val) {
        this.$store.commit('UPDATE_WIDTH', val);
      }
    },
    height: {
      get () {
        return this.$store.state.form.height;
      },
      set (val) {
        this.$store.commit('UPDATE_HEIGHT', val);
      }
    },
    length: {
      get () {
        return this.$store.state.form.length;
      },
      set (val) {
        this.$store.commit('UPDATE_LENGTH', val);
      }
    },
    weight: {
      get () {
        return this.$store.state.form.weight;
      },
      set (val) {
        this.$store.commit('UPDATE_WEIGHT', val);
      }
    }
  },
  mounted () {
    const storedInsurance = this.$store.state.form.insuranceValue;
    if (storedInsurance) {
      this.price = `R$ ${this.$store.state.form.insuranceValue}`;
    }
    this.checkCEP(this.to, 'to');
    this.checkCEP(this.from, 'from');
  },
  methods: {
    setFocus (id) {
      document.getElementById(id).focus();
    },
    stripTheGarbage (e, from = null) {
      const dotWeight = from === 'weight' && e.keyCode === 44 && !this.weight.includes(',');
      if (e.keyCode === 13) {
        return;
      } else if ((e.keyCode < 48 || e.keyCode > 59)) {
        if (dotWeight) {
          return;
        }
        e.preventDefault();
      }
    },
    iptFocus (ipt) {
      this.focus[ipt] = true;
    },
    iptBlur (ipt) {
      this.focus[ipt] = false;
      this.v$[ipt].$touch();
    },
    onSubmit () {
      if (this.v$.$invalid) {
        this.v$.$touch();
        return;
      }

      if (this.price.includes('R$')) {
        this.price = this.price.split(' ')[1];
      }

      if (this.price.length >= 8) {
        this.price = this.price.replace(/\./g, '');
      }

      this.$store.commit('UPDATE_INSURANCE_VALUE', this.price);

      this.$store.dispatch('calculateValues', {...this.$store.state.form, reverse: this.reverse});

      this.addHashToLocation('resultados');

      const calculatorTop = getOffsetTop(document.querySelector('#calculator'));

      window.scrollTo({
        top: calculatorTop,
        behavior: 'smooth'
      });
    },
    checkCEP(value, element) {
      if (value.length === 10) {
        let temp = parseInt(value.replace(/\D/g, ''));
        PostalCodeZones.some((item) => {
          if (temp >= parseInt(item.first) && temp <= parseInt(item.last)) {
            this.state[element] = item.state;
            this.stateStatus[element] = true;
            return true;
          }
        })
      } else {
        this.stateStatus[element] = false;
      }
    },
    addHashToLocation(params) {
      const route = this.$route.path === '/' ? encodeURIComponent(params) : `/${encodeURIComponent(params)}`;
      history.pushState(
        {},
        null,
        this.$route.path + route
      );
    }
  },
  validations() {
    return {
      from: {
        required,
        minLength: minLength(8)
      },
      to: {
        required,
        minLength: minLength(8)
      },
      height: {
        required,
        realDimension
      },
      width: {
        required,
        realDimension
      },
      length: {
        required,
        realDimension
      },
      weight: {
        required,
        realWeight
      },
      price: {
        realMoney
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.button-box
  @media screen and (min-width 768px)
    margin 0 auto
    width 30%

.secondary-text--big
  margin-bottom 24px

  @media screen and (min-width 1048px)
    margin-bottom 32px
</style>
