<template>
  <div class="faq">
    <div class="faq__container container">
      <div class="faq__content">
        <h3 class="faq__content__title text--title text-color--white">Dúvidas Frequentes</h3>
        <p class="faq__content__description text--normal text-color--white">
          Ficou com alguma dúvida? Confira nossa lista das perguntas mais frequentes.
        </p>
        <a
          class="cta text-color--white"
          href="https://menv.io/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saber mais
          <ion-icon name="arrow-forward-outline" class="icon"></ion-icon>
        </a>
      </div>
      <MUIAccordionList :accordions="faq" />
    </div>
  </div>
</template>

<script>
import MUIAccordionList from '../../../commons/js/components/organisms/MUIAccordionList.vue'
import {questionsAndAnswers} from '../config/faq'

export default {
  components: {
    MUIAccordionList
  },

  data() {
    return {
      faq: questionsAndAnswers
    }
  }
}
</script>

<style lang="stylus" scoped>
$theme-primary = #00519d
$theme-secondary = #f3b331

.faq
  background-color $theme-primary
  width 100%
  padding 60px 0

  &__container
    display flex
    flex-direction column
    gap 40px

    @media screen and (min-width 1048px)
      display grid
      grid-template-columns repeat(2, 48%)
      justify-content space-between

  &__content
    display flex
    flex-direction column
    justify-content center
    align-items center
    text-align center
    gap 24px

    @media screen and (min-width 1048px)
      align-items start
      justify-content start
      margin-top 110px
      text-align left

    &__title
      margin 0
</style>
