<template>
  <div class="what-we-do-for-you container">
    <div class="what-we-do-for-you__content">
      <h3 class="text--title text-color--primary">Descomplicamos o frete para você vender mais</h3>
      <p class="secondary-text--big text-color--neutral-dark">
        O Melhor Envio é o maior parceiro de logística do seu e-commerce
        e oferece muito mais do que fretes baratos. Confira:
      </p>
    </div>
    <ul class="what-we-do-for-you__cards">
      <li
        class="what-we-do-for-you__card"
        v-for="card in cardsContent"
        :key="card.title"
      >
        <img :src="card.icon" alt="" role="presentation">
        <h4 class="text--subtitle text-color--primary">{{ card.title }}</h4>
        <p class="text--normal text-color--neutral-dark">{{ card.description }}</p>
      </li>
      <span class="text--small text-color--neutral-dark">*Confira a disponibilidade</span>
    </ul>
    <div class="what-we-do-for-you__cta">
      <a href="/cadastre-se" class="button button--primary">Cadastrar</a>
    </div>
  </div>
</template>

<script>
import { whatWeDoForYou } from '../config/whatWeDoForYou';

export default {
  data() {
    return {
      cardsContent: whatWeDoForYou
    }
  }
}
</script>

<style lang="stylus" scoped>
.what-we-do-for-you
  text-align center
  padding 40px 0 0

  @media screen and (min-width 768px)
    padding 50px 0 0

  @media screen and (min-width 1048px)
    padding 60px 0 0

  &__cards
    margin 24px auto 0
    display grid
    gap 8px

    @media screen and (min-width 768px)
      grid-template-columns 1fr 1fr

    @media screen and (min-width 1048px)
      max-width 70%
      margin-top 32px auto 0
      gap 16px

  &__card
    border-radius 4px
    background-color white
    box-shadow 0px 2px 5px 0px rgba(38, 48, 60, 0.20)
    padding 16px 16px 24px 16px
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 16px

    img
      width 56px

      @media screen and (min-width 1048px)
        width 64px

  span
    text-align left

    @media screen and (min-width 1048px)
      margin-top -8px

  &__cta
    max-width 80%
    margin 16px auto 0

    @media screen and (min-width 768px)
      max-width 50%

    @media screen and (min-width 1048px)
      max-width 30%

    a
      width auto
</style>
