<template>
  <div>
    <MUIAccordion
      v-for="accordion in accordions"
      :key="accordion.title"
      :title="accordion.title"
      :text="accordion.text"
      ref="accordion"
      @accordion-toggle="handleAccordionOpen"
    />
  </div>
</template>

<script>
import MUIAccordion from "../atoms/MUIAccordion.vue";

export default {
  name: "mui-accordion-list",

  components: {
    MUIAccordion,
  },

  props: {
    accordions: {
      type: Array,
      default: () => [],
    },

    /**
     * Control if is allowed multiple accordions opened at once.
     */
    multipleOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Close the opened accordions if {multipleOpen} is set to true.
     * @returns {void}
     */
    handleAccordionOpen({ status, uid }) {
      if (this.multipleOpen || !status) {
        return;
      }

      this.$refs.accordion.map((ref) => {
        if (ref.uid !== uid) {
          ref.close();
        }

        return ref;
      });
    },
  },
};
</script>
