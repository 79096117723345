import Main from '@newhome/js/layouts/Main'
import Jadlog from '@newhome/js/components/Modals/Jadlog'
import Loggi from '@newhome/js/components/Modals/Loggi'
import Correios from '@newhome/js/components/Modals/Correios'
import Error404 from '@newhome/js/components/404'

export default [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Melhor Envio | Economize na gestão de fretes',
      description: 'Com o Melhor Envio você cota simultaneamente com diversas transportadoras e gera etiqueta de envios com rastreio automático em uma plataforma gratuita'
    }
  },

  {
    path: '/transportadora-jadlog',
    component: Jadlog,
    meta: {
      title: 'Transportadora Jadlog: o que é e como funciona o envio',
      description: 'Transportadora Jadlog: veja como funciona no Melhor Envio e tenha fretes até 80% mais baratos'
    }
  },

  {
    path: '/transportadora-loggi-entrega',
    component: Loggi,
    meta: {
      title: 'Transportadora Loggi | Melhor Envio',
      description: 'A transportadora Loggi é umas das parceiras do Melhor Envio para seu e-commerce enviar para todo o Brasil com até 80% de desconto.'
    }
  },

  {
    path: '/calcular-frete-correios',
    component: Correios,
    meta: {
      title: 'Calcular Frete Correios - Calculadora de Preços e Prazos',
      description: 'A Calculadora de Frete do Melhor Envio é a melhor forma de Calcular o Frete dos Correios e descobrir os preços e prazos de forma descomplicada.'
    }
  },

  {
    path: '/resultados',
    redirect: '/'
  },

  {
    path: '/home/resultados',
    redirect: '/home'
  },

  {
    path: '/404',
    component: Error404,
    props: {
      user: true
    }
  },

  {
    path: '/*',
    redirect: '/404'
  }
]
