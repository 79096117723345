<template>
  <transition name="modal-fade">
    <div class="modal-home">
      <PictureImage
        folderName="modals"
        fileName="modal-jadlog.png"
        classNames="modal-home__image"
        :useIntersect="false"
      />
      <div
        class="modal-home__close-button"
        @click="closeModal">
        <ion-icon name="close"></ion-icon>
      </div>

      <div class="modal-home__content container">
        <h1 class="text--title text-color--neutral-dark">Transportadora Jadlog: o que é e como funciona o envio</h1>

        <p class="modal-home__text text-color--neutral-dark text--normal">
          Não é segredo que o <strong>custo do frete</strong> é um grande vilão e o principal motivo das
          <strong>desistências nas compras</strong> pela internet.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Mas o que você tem feito para diminuir o número de <strong>carrinho abandonado</strong> na sua loja?
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Quem vende pela internet, precisa oferecer a melhor experiência de compra e opções de frete aos clientes.
          <strong>A solução para isso é a transportadora Jadlog.</strong>
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="help-circle-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">O que é a Jadlog?</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A Transportadora Jadlog é uma empresa brasileira especializada em
          <strong>serviços de logística e transporte de cargas fracionadas</strong> com cobertura nacional e internacional.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Fundada em 2005, a empresa se destaca pela variedade de serviços oferecidos, como coleta,
          entrega expressa e econômica e rastreamento de encomendas.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Ao escolher o <strong>frete da transportadora Jadlog com o Melhor Envio</strong>,
          você encontra condições mais vantajosas do que as praticadas no balcão.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Assim você oferece <strong>frete barato</strong> no seu e-commerce, sem precisar de um contrato com a Jadlog.
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="cube-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Como funciona o envio pela Jadlog?</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A transportadora Jadlog é uma empresa parceira do Melhor Envio. Com ela o envio de
          encomendas é descomplicado e feito seguindo essas 4 etapas:
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>1. Faça a cotação do frete</strong><br>
          O primeiro passo é calcular o frete da Jadlog e escolher o que melhor atende o seu negócio
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>2. Gere a etiqueta</strong><br>
          A etiqueta é o documento mais importante. Nela estão as informações do remetente e do destinatário
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>3. Prepare sua encomenda para envio</strong><br>
          Agora é só separar os produtos, embalar e colar etiqueta e Nota Fiscal na embalagem
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>4. Despache seu pedido</strong><br>
          Por último é só encaminhar o pedido para uma agência da Jadlog logística ou para alguma das
          <a href="https://www.melhorponto.com.br/unidades/" target="_blank" rel="noopener noreferrer">unidades do Melhor Ponto</a>
          - centralizador de postagens do Melhor Envio.
        </p>

        <p class="modal-home__text text-color--neutral-dark text--normal">
          Lembrando que a <strong>Jadlog faz até 3 tentativas de entrega, sem custo adicional</strong>.
          Se após esse prazo a entrega não for concluída, o destinatário tem até 5 dias para coletar a mercadoria.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Caso a encomenda não seja retirada, o envio retorna para o remetente com cobrança de 100% do preço do frete.
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="reader-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Quais são os serviços da transportadora Jadlog?</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A Jadlog oferece 9 serviços com diferentes modalidades de envio. Assim, você consegue escolher entre
          envios mais econômicos ou mais rápidos e até por entregas no endereço ou em pontos de distribuição.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Confira quais são os serviços da Jadlog logística:
        </p>
        <ul class="modal-home__list">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Pickup: clientes retiram suas encomendas em estabelecimentos parceiros
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog.com: envio rápido para produtos com até 30kg, vendidos pela internet
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Package: indicado para envios de pequenos volumes B2C ou B2B com prazo rodoviário
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Predict: serviço que avisa o horário de entrega das encomendas
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Rodo: ideal para envios com peso até 50kg com prazo rodoviário
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Rodo Econômico: transporte de carga fracionada B2B na modalidade rodoviária
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Cargo: serviço de transporte B2B para cargas aéreas
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog DOC: entregas de documentos na medida para o lojista
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Jadlog Internacional: envio de documentos e de encomendas com menos de 30kg para 154 países
          </li>
        </ul>

        <h3 class="text-color--neutral-dark secondary-text--big">Jadlog Package ou Jadlog.com? Entenda a diferença</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Uma dúvida comum de quem precisa enviar encomendas com a transportadora Jadlog é:
          qual a diferença entre Jadlog Package e Jadlog.com?
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>
            Jadlog Package é uma modalidade econômica de envio, já as remessas
            contratadas no modelo Jadlog.com são fretes expressos.
          </strong>
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Dessa forma, as entregas realizadas no modelo Jadlog.com são mais rápidas
          (entre 3 e 5 dias, em média), mas custam um pouco mais.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Mais vantagens na cotação Jadlog</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          A boa notícia é que, enviando com a Jadlog pelo Melhor Envio, os fretes nas modalidades
          Jadlog Package e Jadlog.com podem ser encontrados por um preço bem parecido.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          E quem usa o <a href="https://www.melhorponto.com.br/unidades/" target="_blank" rel="noopener noreferrer">Melhor Ponto</a>
          recebe descontos exclusivos no preço do frete.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Para aproveitar, basta escolher a modalidade <strong>Jadlog Package Centralizado</strong> e depois postar
          a encomenda na unidade do Melhor Ponto mais próxima do seu endereço.
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="thumbs-up-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Como contratar a transportadora Jadlog?</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <strong>Contratar a Jadlog transportadora para sua loja virtual é simples e pode ser feito usando
          plataformas de intermediação logística</strong> como o Melhor Envio.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Com o Melhor Envio, você envia quantos produtos quiser por mês, sem limite mínimo e ainda
          <strong>ganha descontos de até 80%</strong> em relação ao preço do frete praticado no balcão da transportadora.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          <i>Nós conectamos o seu e-commerce à transportadora Jadlog e você foca no que realmente importa, o seu negócio.</i>
        </p>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="cube-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Como enviar pela Jadlog no Melhor Envio?</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          No Melhor Envio você pode calcular fretes em segundos e comparar preços e prazos da transportadora Jadlog
          com a liberdade de escolher a opção que mais faz sentido para o seu negócio.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Veja como é fácil enviar pela Jadlog:
        </p>
        <ol class="modal-home__list modal-home__list--number">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Acesse a <a href="https://app.melhorenvio.com.br/calculadora" target="_blank" rel="noopener noreferrer">Calculadora de Frete</a>
            do Melhor Envio e preencha os CEPs de origem e de destino, valor segurado, peso e dimensões da embalagem.
            Depois, clique em “Calcular”
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Escolha o frete Jadlog de sua preferência e clique em “Selecionar”
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Selecione o documento que vai acompanhar o objeto: Nota Fiscal ou Declaração de Conteúdo
          </li>
        </ol>

        <div class="modal-home__subtitle">
          <ion-icon class="text-color--primary modal-home__subtitle--icon" name="map-outline"></ion-icon>
          <h2 class="text-color--neutral-dark">Transportadora Jadlog rastreio como fazer?</h2>
        </div>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Rastrear pedidos na Jadlog é simples. Confira o passo a passo:
        </p>
        <ol class="modal-home__list modal-home__list--number">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Acesse o site da transportadora Jadlog –
            <a href="https://www.jadlog.com.br" target="_blank" rel="noopener noreferrer">www.jadlog.com.br</a>
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            No canto superior direito, encontre a caixa “Rastreie sua encomenda”
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Coloque seu CPF ou o código de rastreamento e clique em “Procurar”
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Selecione o status da entrega clicando na seta ao lado do pedido
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Por fim, clique em ‘Mostrar detalhes’ e veja os detalhes do seu envio.
          </li>
        </ol>

        <h3 class="text-color--neutral-dark secondary-text--big">Como fazer o rastreamento Jadlog no Melhor Envio</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          O Melhor Envio oferece uma ferramenta gratuita para rastrear pedidos feitos com a Jadlog e
          outras transportadoras em um só lugar, o Melhor Rastreio.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Assim você e seu cliente não precisam entrar em diversos sites para acompanhar um pacote.
          É uma experiência completa para fazer o rastreio da Jadlog.
        </p>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Veja como:
        </p>
        <ol class="modal-home__list modal-home__list--number">
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Acesse sua conta no
            <a href="https://app.melhorrastreio.com.br/app/" target="_blank" rel="noopener noreferrer">Melhor Rastreio</a>
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Encontre o campo <strong>Rastreie sua encomenda</strong>, no topo da página
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Informe o código da encomenda e clique em "Pesquisar"
          </li>
          <li class="modal-home__text text-color--neutral-dark text--normal">
            Prontinho! Agora é só acompanhar as atualizações do seu pacote com a Jadlog.
          </li>
        </ol>

        <h3 class="text-color--neutral-dark secondary-text--big">Qual o horário de entrega da transportadora Jadlog?</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Os serviços de entrega da Jadlog logística funcionam em horários parecidos com os de outras transportadoras.
          As entregas são feitas em horário comercial durante a semana, de segunda a sexta-feira e aos sábados.
        </p>

        <h3 class="text-color--neutral-dark secondary-text--big">Jadlog entrega sábado?</h3>
        <p class="modal-home__text text-color--neutral-dark text--normal">
          Sim, a Jadlog entrega sábado das 8h às 21h. Já o horário de atendimento das agências vai até às 12h
          na maioria dos locais. Sendo que algumas cidades, sobretudo do interior, não abrem aos sábados.
        </p>

        <div class="modal-home__cta">
          <a class="cta" href="/">
            Saiba mais vantagens do Melhor Envio
            <ion-icon name="arrow-forward-outline" class="icon"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PictureImage from '../Common/Picture.vue';

export default {
  components: {
    PictureImage
  },

  methods: {
    closeModal () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

