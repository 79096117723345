// Correios

const CORREIOS_ID = 1;

const CORREIOS_PAC_ID = 1;
const CORREIOS_SEDEX_ID = 2;
const CORREIOS_ESEDEX_ID = 14;
const CORREIOS_MINI_ID = 17;
const CORREIOS_PAC_GRANDES_FORMATOS_ID = 23;
const CORREIOS_SEDEX_GRANDES_FORMATOS_ID = 24;
const CORREIOS_PAC_CENTRALIZADO_ID = 28;
const CORREIOS_SEDEX_CENTRALIZADO_ID = 29;
const CORREIOS_MINI_CENTRALIZADO_ID = 30;

const CORREIOS_SERVICES_IDS = [
  CORREIOS_PAC_ID,
  CORREIOS_SEDEX_ID,
  CORREIOS_ESEDEX_ID,
  CORREIOS_MINI_ID,
  CORREIOS_PAC_GRANDES_FORMATOS_ID,
  CORREIOS_SEDEX_GRANDES_FORMATOS_ID,
  CORREIOS_PAC_CENTRALIZADO_ID,
  CORREIOS_SEDEX_CENTRALIZADO_ID,
  CORREIOS_MINI_CENTRALIZADO_ID,
];

// Jadlog

const JADLOG_ID = 2;

const JADLOG_DOTPACKAGE_ID = 3;
const JADLOG_DOTCOM_ID = 4;
const JADLOG_DOTPACKAGECENTRALIZADO_ID = 27;

const JADLOG_SERVICES_IDS = [
  JADLOG_DOTPACKAGE_ID,
  JADLOG_DOTCOM_ID,
  JADLOG_DOTPACKAGECENTRALIZADO_ID
];

// Jamef

const JAMEF_ID = 4;

const JAMEF_AEREO_ID = 6;
const JAMEF_RODOVIARIO_ID = 7;

const JAMEF_SERVICES_IDS = [
  JAMEF_AEREO_ID,
  JAMEF_RODOVIARIO_ID,
];

// LATAM Cargo

const LATAM_CARGO_ID = 6;

const LATAM_CARGO_PROXIMO_DIA_ID = 10;
const LATAM_CARGO_PROXIMO_VOO_ID = 11;
const LATAM_CARGO_EFACIL_ID = 12;

const LATAM_CARGO_SERVICES_IDS = [
  LATAM_CARGO_PROXIMO_DIA_ID,
  LATAM_CARGO_PROXIMO_VOO_ID,
  LATAM_CARGO_EFACIL_ID,
];

// Total Express

const TOTAL_EXPRESS_ID = 8;

const TOTAL_EXPRESS_EXPRESSO_ID = 18;

const TOTAL_EXPRESS_SERVICES_IDS = [
  TOTAL_EXPRESS_EXPRESSO_ID,
];

// Azul Cargo Express

const AZUL_CARGO_EXPRESS_ID = 9;

const AZUL_CARGO_EXPRESS_AMANHA_ID = 15;
const AZUL_CARGO_EXPRESS_ECOMMERCE_ID = 16;

const AZUL_CARGO_EXPRESS_SERVICES_IDS = [
  AZUL_CARGO_EXPRESS_AMANHA_ID,
  AZUL_CARGO_EXPRESS_ECOMMERCE_ID,
];

// Correios Contract

const CORREIOS_CONTRACT_ID = 10;

const CORREIOS_CONTRACT_PAC_ID = 19;
const CORREIOS_CONTRACT_SEDEX_ID = 20;

const CORREIOS_CONTRACT_SERVICES_IDS = [
  CORREIOS_CONTRACT_PAC_ID,
  CORREIOS_CONTRACT_SEDEX_ID,
];

// Buslog

const BUSLOG_ID = 12;

const BUSLOG_RODOVIARIO_ID = 22;

const BUSLOG_SERVICES_IDS = [
  BUSLOG_RODOVIARIO_ID
];

// Loggi Express

const LOGGI_ID = 14;
const LOGGI_EXPRESS_ID = 31;
const LOGGI_COLLECT_ID = 32;
const LOGGI_PONTO_ID = 34;

const LOGGI_SERVICES_IDS = [LOGGI_EXPRESS_ID, LOGGI_COLLECT_ID, LOGGI_PONTO_ID];

// J&T

const JET_ID = 15;
const JET_STANDARD_ID = 33;

const JET_SERVICES_IDS = [JET_STANDARD_ID];

// Unlogged Calculator

const NEWHOME_UNLOGGED_CALCULATOR_AVAILABLE_SHIPPING_SERVICES = [
  CORREIOS_PAC_ID,
  CORREIOS_SEDEX_ID,
  CORREIOS_MINI_ID,

  LOGGI_EXPRESS_ID,
  LOGGI_COLLECT_ID,
  LOGGI_PONTO_ID,

  JADLOG_DOTPACKAGE_ID,
  JADLOG_DOTCOM_ID,
  JADLOG_DOTPACKAGECENTRALIZADO_ID,

  LATAM_CARGO_PROXIMO_DIA_ID,
  LATAM_CARGO_EFACIL_ID,

  AZUL_CARGO_EXPRESS_AMANHA_ID,
  AZUL_CARGO_EXPRESS_ECOMMERCE_ID,

  CORREIOS_CONTRACT_PAC_ID,
  CORREIOS_CONTRACT_SEDEX_ID,

  BUSLOG_ID,
  BUSLOG_RODOVIARIO_ID,

  JET_STANDARD_ID,
];

/**
 * Este dado ficará temporariamente neste arquivo
 * até a existência de uma forma de consulta do backend.
 */
const SHIPPING_SERVICES_DECLARATION_INSURANCE_RESTRICTIONS = {
  [CORREIOS_PAC_ID]: 3000,
  [CORREIOS_SEDEX_ID]: 10000,
  [CORREIOS_MINI_ID]: 100,
  [CORREIOS_PAC_CENTRALIZADO_ID]: 3000,
  [CORREIOS_SEDEX_CENTRALIZADO_ID]: 10000,
  [CORREIOS_MINI_CENTRALIZADO_ID]: 100,

  [JADLOG_DOTPACKAGE_ID]: 1000,
  [JADLOG_DOTCOM_ID]: 1000,
  [JADLOG_DOTPACKAGECENTRALIZADO_ID]: 1000,

  [LATAM_CARGO_PROXIMO_DIA_ID]: 1000,
  [LATAM_CARGO_EFACIL_ID]: 1000,

  [AZUL_CARGO_EXPRESS_AMANHA_ID]: 1000,
  [AZUL_CARGO_EXPRESS_ECOMMERCE_ID]: 1000,

  [BUSLOG_RODOVIARIO_ID]: 1000,

  [LOGGI_ID]: 1000,
  [LOGGI_EXPRESS_ID]: 1000,
  [LOGGI_COLLECT_ID]: 1000
};

const SHIPPING_SERVICES_MAX_INSURANCE_VALUES = {
  [CORREIOS_PAC_ID]: 3000,
  [CORREIOS_SEDEX_ID]: 10000,
  [CORREIOS_MINI_ID]: 100,
  [CORREIOS_PAC_GRANDES_FORMATOS_ID]: 3000,
  [CORREIOS_SEDEX_GRANDES_FORMATOS_ID]: 10000,
  [CORREIOS_PAC_CENTRALIZADO_ID]: 3000,
  [CORREIOS_SEDEX_CENTRALIZADO_ID]: 10000,
  [CORREIOS_MINI_CENTRALIZADO_ID]: 100,

  [JADLOG_DOTPACKAGE_ID]: 30000,
  [JADLOG_DOTCOM_ID]: 30000,
  [JADLOG_DOTPACKAGECENTRALIZADO_ID]: 29900,

  [LATAM_CARGO_PROXIMO_DIA_ID]: 10000,
  [LATAM_CARGO_EFACIL_ID]: 10000,

  [AZUL_CARGO_EXPRESS_AMANHA_ID]: 40000,
  [AZUL_CARGO_EXPRESS_ECOMMERCE_ID]: 40000,

  [BUSLOG_RODOVIARIO_ID]: 50000,

  [LOGGI_ID]: 1000,
  [LOGGI_EXPRESS_ID]: 10000,
  [LOGGI_COLLECT_ID]: 10000
};

/**
 * Transportadoras que não possuem envio de múltiplos volumes
 */
const SHIPPING_SERVICES_WITHOUT_MULTIPLE_VOLUMES = [
  CORREIOS_ID,
  LOGGI_ID
]

/**
 * Serviços industriais
 */
const INDUSTRIAL_SHIPPING_SERVICES = [
  JADLOG_DOTPACKAGECENTRALIZADO_ID,
  CORREIOS_PAC_CENTRALIZADO_ID,
  CORREIOS_SEDEX_CENTRALIZADO_ID,
  CORREIOS_MINI_CENTRALIZADO_ID,
  LOGGI_EXPRESS_ID,
  LOGGI_COLLECT_ID,
  JET_STANDARD_ID
]

// Adicione o ID da transportadora cuja declaração não pode ser editada.

const COMPANIES_THAT_CANNOT_EDIT_DECLARATIONS = [];

/**
 * Tracking Default Type
 */
const TRACKER_DEFAULT_TYPE = 0;

export {
  NEWHOME_UNLOGGED_CALCULATOR_AVAILABLE_SHIPPING_SERVICES as newhomeUnloggedCalculatorAvailableShippingServices,
  SHIPPING_SERVICES_DECLARATION_INSURANCE_RESTRICTIONS,
  SHIPPING_SERVICES_MAX_INSURANCE_VALUES,
  CORREIOS_ID
};

export default {
  CORREIOS_ID,
  CORREIOS_PAC_ID,
  CORREIOS_SEDEX_ID,
  CORREIOS_ESEDEX_ID,
  CORREIOS_MINI_ID,
  CORREIOS_SERVICES_IDS,
  CORREIOS_PAC_GRANDES_FORMATOS_ID,
  CORREIOS_SEDEX_GRANDES_FORMATOS_ID,
  CORREIOS_PAC_CENTRALIZADO_ID,
  CORREIOS_SEDEX_CENTRALIZADO_ID,
  CORREIOS_MINI_CENTRALIZADO_ID,

  JADLOG_ID,
  JADLOG_DOTPACKAGE_ID,
  JADLOG_DOTCOM_ID,
  JADLOG_DOTPACKAGECENTRALIZADO_ID,
  JADLOG_SERVICES_IDS,

  JAMEF_ID,
  JAMEF_AEREO_ID,
  JAMEF_RODOVIARIO_ID,
  JAMEF_SERVICES_IDS,

  LATAM_CARGO_ID,
  LATAM_CARGO_PROXIMO_DIA_ID,
  LATAM_CARGO_PROXIMO_VOO_ID,
  LATAM_CARGO_EFACIL_ID: LATAM_CARGO_EFACIL_ID,
  LATAM_CARGO_SERVICES_IDS,

  TOTAL_EXPRESS_ID,
  TOTAL_EXPRESS_EXPRESSO_ID,
  TOTAL_EXPRESS_SERVICES_IDS,

  AZUL_CARGO_EXPRESS_ID,
  AZUL_CARGO_EXPRESS_AMANHA_ID,
  AZUL_CARGO_EXPRESS_ECOMMERCE_ID,
  AZUL_CARGO_EXPRESS_SERVICES_IDS,

  BUSLOG_ID,
  BUSLOG_RODOVIARIO_ID,
  BUSLOG_SERVICES_IDS,

  CORREIOS_CONTRACT_ID,
  CORREIOS_CONTRACT_PAC_ID,
  CORREIOS_CONTRACT_SEDEX_ID,
  CORREIOS_CONTRACT_SERVICES_IDS,
  SHIPPING_SERVICES_MAX_INSURANCE_VALUES,

  LOGGI_ID,
  LOGGI_EXPRESS_ID,
  LOGGI_COLLECT_ID,
  LOGGI_PONTO_ID,
  LOGGI_SERVICES_IDS,

  JET_ID,
  JET_STANDARD_ID,

  TRACKER_DEFAULT_TYPE,

  methods: {
    isCorreios: (id) => parseInt(id) === CORREIOS_ID,

    isCorreiosService: (id) => CORREIOS_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isCorreiosPAC: (id) => parseInt(id) === CORREIOS_PAC_ID,

    isCorreiosSedex: (id) => parseInt(id) === CORREIOS_SEDEX_ID,

    isCorreiosMini: (id) => parseInt(id) === CORREIOS_MINI_ID,

    isCorreiosPacGF: (id) => parseInt(id) === CORREIOS_PAC_GRANDES_FORMATOS_ID,

    isCorreiosSedexGF: (id) => parseInt(id) === CORREIOS_SEDEX_GRANDES_FORMATOS_ID,

    isCorreiosPacCentralizado: (id) => parseInt(id) === CORREIOS_PAC_CENTRALIZADO_ID,

    isCorreiosSedexCentralizado: (id) => parseInt(id) === CORREIOS_SEDEX_CENTRALIZADO_ID,

    isCorreiosMiniCentralizado: (id) => parseInt(id) === CORREIOS_MINI_CENTRALIZADO_ID,

    isJadlog: (id) => parseInt(id) === JADLOG_ID,

    isJadlogService: (id) => JADLOG_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isJadlogDotPackageCentralizado: (id) => parseInt(id) === JADLOG_DOTPACKAGECENTRALIZADO_ID,

    isJamef: (id) => parseInt(id) === JAMEF_ID,

    isJamefService: (id) => JAMEF_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isLATAMCargo: (id) => parseInt(id) === LATAM_CARGO_ID,

    isLATAMCargoService: (id) => LATAM_CARGO_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isTotalExpress: (id) => parseInt(id) === TOTAL_EXPRESS_ID,

    isTotalExpressService: (id) => TOTAL_EXPRESS_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isAzulCargoExpress: (id) => parseInt(id) === AZUL_CARGO_EXPRESS_ID,

    isAzulCargoExpressService: (id) => AZUL_CARGO_EXPRESS_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isCorreiosContract: (id) => parseInt(id) === CORREIOS_CONTRACT_ID,

    isCorreiosContractService: (id) => CORREIOS_CONTRACT_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isAvailableService: (id) => NEWHOME_UNLOGGED_CALCULATOR_AVAILABLE_SHIPPING_SERVICES.indexOf(parseInt(id)) !== -1,

    isAnyCorreios: (id) => [CORREIOS_ID, CORREIOS_CONTRACT_ID].indexOf(parseInt(id)) !== -1,

    isAnyCorreiosService: (id) => CORREIOS_SERVICES_IDS.concat(CORREIOS_CONTRACT_SERVICES_IDS).indexOf(parseInt(id)) !== -1,

    isBuslog: (id) => parseInt(id) === BUSLOG_ID,

    isBuslogService: (id) => BUSLOG_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    lacksMultipleVolumes: (id) => SHIPPING_SERVICES_WITHOUT_MULTIPLE_VOLUMES.indexOf(parseInt(id)) !== -1,

    isIndustrialService: (id) => INDUSTRIAL_SHIPPING_SERVICES.indexOf(parseInt(id)) !== -1,

    canEditDeclaration: (id) => !COMPANIES_THAT_CANNOT_EDIT_DECLARATIONS.includes(parseInt(id)),

    isLoggi: (id) => parseInt(id) === LOGGI_ID,

    isLoggiExpress: (id) => parseInt(id) === LOGGI_EXPRESS_ID,

    isLoggiCollect: (id) => parseInt(id) === LOGGI_COLLECT_ID,

    isLoggiPonto: (id) => parseInt(id) === LOGGI_PONTO_ID,

    isLoggiService: id => LOGGI_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

    isJET: (id) => parseInt(id) === JET_ID,

    isJETStandard: (id) => parseInt(id) === JET_STANDARD_ID,

    isJETService: id => JET_SERVICES_IDS.indexOf(parseInt(id)) !== -1,

  },
};
